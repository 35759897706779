/* HowCreditsWork.css */
.page-container {
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    background-color: #f5f5f5;
  }
  
  .credits-main {
    flex: 1;
    padding: 40px 20px;
  }
  
  .content-container {
    max-width: 800px;
    margin: 0 auto;
  }
  
  .page-header {
    text-align: center;
    margin-bottom: 40px;
  }
  
  .page-header h1 {
    font-size: 2.5rem;
    color: #333;
    margin-bottom: 16px;
  }
  
  .intro-text {
    font-size: 1.2rem;
    color: #666;
    line-height: 1.6;
  }
  
  .info-section {
    background: white;
    border-radius: 8px;
    padding: 24px;
    margin-bottom: 24px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.05);
  }
  
  .info-section h2 {
    font-size: 1.5rem;
    color: #333;
    margin-bottom: 16px;
  }
  
  .info-list {
    list-style: none;
    padding: 0;
    margin: 16px 0;
    background: #f8f9fa;
    padding: 20px;
    border-radius: 6px;
  }
  
  .info-list li {
    margin-bottom: 12px;
    padding-left: 20px;
    position: relative;
  }
  
  .info-list li:before {
    content: "•";
    color: #4a90e2;
    position: absolute;
    left: 0;
  }
  
  .numbered-list {
    padding-left: 20px;
    margin: 16px 0;
  }
  
  .numbered-list li {
    margin-bottom: 12px;
  }
  
  .download-box {
    background: #e8f4ff;
    padding: 24px;
    border-radius: 8px;
    text-align: center;
    margin-top: 24px;
  }
  
  .download-button {
    display: inline-flex;
    align-items: center;
    background: #4a90e2;
    color: white;
    padding: 12px 24px;
    border-radius: 6px;
    text-decoration: none;
    margin-top: 16px;
    transition: background-color 0.2s;
  }
  
  .download-button:hover {
    background: #357abd;
  }
  
  .download-icon {
    width: 20px;
    height: 20px;
    margin-right: 8px;
    stroke: currentColor;
    stroke-width: 2;
    fill: none;
    stroke-linecap: round;
    stroke-linejoin: round;
  }
  
  .closing-note {
    text-align: center;
    color: #666;
    margin-top: 40px;
  }
  
  /* Responsive design */
  @media (max-width: 768px) {
    .credits-main {
      padding: 20px 16px;
    }
  
    .page-header h1 {
      font-size: 2rem;
    }
  
    .info-section {
      padding: 20px;
    }
  }