.privacy-policy {
    font-family: Arial, sans-serif;
    margin: 20px auto;
    max-width: 800px;
    padding: 20px;
    line-height: 1.6;
    color: #333;
  }
  
  .privacy-policy h1 {
    color: #184a63;
  }
  
  .privacy-policy h2 {
    color: #184a63;
    margin-top: 20px;
  }
  
  .privacy-policy p {
    margin-top: 10px;
  }
  
  .privacy-policy a {
    color: #184a63;
    text-decoration: none;
  }
  
  .privacy-policy a:hover {
    text-decoration: underline;
  }
  