.fontawesome-dropdown-container {
    position: relative;
    display: flex;
    align-items: center;
    gap: 10px; /* Adjust as necessary */
  }
  
  .fontawesome-dropdown-container .fa {
    font-size: 1em; /* Adjust icon size as needed */
  }
  
  .fontawesome-dropdown {
    width: 100%;
    padding: 10px 15px;
    font-family: FontAwesome, "Open Sans", sans-serif;
    border-radius: 4px;
    -webkit-appearance: none; /* Removes default chrome and safari style */
    -moz-appearance: none; /* Removes default style Firefox */
    appearance: none; /* Remove default arrow */
    background: #fff url('data:image/png;base64,...') no-repeat 95% center; /* Add custom arrow */
    cursor: pointer;
  }
  
  /* Media query for smaller screens */
  @media (max-width: 600px) {
    .fontawesome-dropdown {
      font-size: 14px;
    }
  }
  
  /* Styling for options */
  .fontawesome-dropdown option {
    padding: 10px;
  }
  