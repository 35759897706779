/* Style for the entire home container */
.home-container {
    display: flex; /* Use flexbox to lay out children side by side */
    height: 100vh; /* Set the height to the full viewport height */
    overflow: hidden; /* Hide all content that goes beyond the viewport height */
  }
  
  /* Style for the sidebar */
  .sidebar {
    flex: 0 0 250px; /* Sidebar width set to 250px and not allowed to grow or shrink */
    background-color: #333; /* Adjust the color to match your design */
    color: white; /* Text color for the sidebar */
    padding: 20px; /* Padding around the sidebar content */
    height: 100vh; /* Full height of the view */
    box-sizing: border-box; /* Include padding in the width */
    overflow-y: auto; /* Allow scrolling within the sidebar if content overflows */
  }
  
  /* Style for the main content area where TileGallery will be placed */
  .main-content {
    flex-grow: 1; /* Allow the main content to grow and fill the remaining space */
    padding: 20px; /* Padding around the content */
    background-color: #f8f8f8; /* Adjust the color to match your design */
    overflow-y: auto; /* Add scroll to the content area if content overflows */
    overflow-x:hidden;
  }
  
  /* Ensure the body does not have its own margin */
  body {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
  }
  