.tile-gallery-container {
  display: flex;
  flex-direction: column;
  align-items: flex-start; /* Aligns the container's content to the left */
  gap: 1rem;
  padding: 1rem;
  margin-left: 300px;
}
/* Updated CSS with transition */
.tile .file-actions {
  opacity: 0; /* Start invisible */
  transition: opacity 0.3s ease-in-out; /* Smooth transition for the opacity */
  /* Keep the display:block to make sure the space is allocated and transition can apply */
  display: block;
}

.tile:hover .file-actions {
  opacity: 1; /* Fully visible on hover */
  /* No need to change display property here */
}


.tile-title {
  font-size: 1.5rem;
  font-weight: bold;
  margin: 1rem 0;
}

.tile-gallery {
  display: flex;
  flex-wrap: wrap;
  gap: 1rem;
  justify-content: flex-start; /* Align tiles to the left */
  width: 100%; /* Ensures the gallery takes full width of its container */
}

.tile .fa-file { /* Assuming .fa-file is the class of the icon */
  font-size: 36px; /* Adjust the size as needed */
}

.file-preview {
  flex-grow: 1;
  display: -webkit-box;
  display: box;
  text-align: left;
  -webkit-box-orient: vertical;
  box-orient: vertical;
  -webkit-line-clamp: 3; /* Adjust the number of lines as needed */
  overflow: hidden;
  text-overflow: ellipsis;
  width: 100%;
  font-weight: 100;
  font-size: 14px;
  padding: 4px; /* Optional: Adjust padding as needed for your design */
  line-height: 1.2; /* Adjust line-height for better text readability */
  margin-bottom: 8px; /* Ensure some space between the preview and the file name */
}

.last-modified{
  font-size: 8px;
  color: #666;
  margin-top: 8px;
  text-align: left;
  font-style:italic;
}


.file-name-tile {
  display: -webkit-box;
  -webkit-line-clamp: 1; /* Number of lines you want to display */
  -webkit-box-orient: vertical;  
  overflow: hidden;
  text-overflow: ellipsis;
  width: 100%;
  text-align: left;
  font-size: 14px;
  margin-top: 8px; /* Adjust based on your layout */
  font-weight: bold;
}

.file-actions {
  position: absolute;
  top: 8px; /* Adjust as needed */
  right: 8px; /* Adjust as needed */
  cursor: pointer;
}

.file-actions svg {
  font-size: 1.2rem;
}

.file-actions svg:hover {
  transform: scale(1.1);
}



.tile {
  position: relative;
  background: #FFFFFF;
  border-radius: 0.5rem;
  border: 1px solid #E0E0E0;
  padding: 1rem;
  text-align: left;
  cursor: pointer;
  transition: box-shadow 0.3s, transform 0.3s;
  width: 200px;
  height: 250px;
  display: flex;
  flex-direction: column;
  justify-content:space-between;
  align-items: left;
}

.tile.loading-tile {
  justify-content: center; /* Override to center content vertically */
  align-items: center; /* Center content horizontally */
  text-align: center; /* Center text horizontally for consistent alignment */
}

/* Optional: Specific styles for the spinner icon */
.fa-spin {
  animation: spin 2s infinite linear;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.new-tile {
  position: relative;
  background: #FFFFFF;
  border-radius: 0.5rem;
  border: 1px solid #E0E0E0;
  padding: 1rem;
  text-align: center;
  cursor: pointer;
  transition: box-shadow 0.3s, transform 0.3s;
  width: 200px;
  height: 200px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.new-tile {
  background: #D9D9D9;
}

.tile:hover, .new-tile:hover {
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  transform: translateY(-0.2rem);
}
.tile:hover{
  background-color: #f4f4f4;
}



@media (max-width: 768px) {
  .tile-gallery-container{
    margin-left:40px;
  }

  .tile, .new-tile {
    width: 45%;
    height: auto;
  }

  .tile-title {
    font-size: 1.2rem;
    
  }

  .file-actions {
    font-size: 0.9rem;
    font-weight:bolder;
    
  }

  .file-actions svg {
    font-size: 1rem;
  }
}

@media (max-width: 480px) {

  .tile-gallery-container{
    margin-left:40px;
  }
  .tile, .new-tile {
    width: 45%; /* Full width on smaller screens for better visibility */
    height: auto;
  }

  .tile-title {
    font-size: 1rem;
  }
 .file-actions {
    font-size: 0.8rem;
    font-weight:bolder;
  }

  .file-actions svg {
    font-size: 0.8rem;
  }
}


.tile-gallery-container {
  display: flex;
  flex-direction: column;
  align-items: flex-start; /* Aligns the container's content to the left */
  gap: 1rem;
  padding: 1rem;
  margin-top: 40px; /* Remove top margin to stick to the top */
 
}

.tile-gallery {
  display: flex;
  flex-wrap: wrap;
  align-content: flex-start; /* Ensures wrapped items are aligned to the top */
  gap: 1rem;
  width: 100%;
}

.tile, .new-tile {
  background: #FFFFFF;
  border-radius: 0.5rem;
  border: 1px solid #E0E0E0;
  padding: 1rem;
  text-align: center;
  cursor: pointer;
  transition: box-shadow 0.3s, transform 0.3s;
  width: 200px; /* Adjust the width as needed */
  height: 200px; /* Adjust the height as needed */
  margin-bottom: 1rem; /* Add some space at the bottom */
}

.new-tile {
  background: #D9D9D9;
}

.tile:hover, .new-tile:hover {
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  transform: translateY(-0.2rem);
}

.tile-title {
  font-size: 1.5rem;
  font-weight: bold;
  margin-bottom: 1rem;
}


/* Styling for Interview call popup */
.call-to-action-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.75);
  display: flex;
  align-items: center;
  justify-content: center;
}

.call-to-action-modal {
  width: 400px;
  height: 300px;
  background: white;
  padding: 20px;
  border-radius: 10px;
  outline: none;
}

.call-to-action-content {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;
  margin: auto;
  width:400px;
}

.call-to-action-content h2 {
  margin-bottom: 20px;
  text-align: center;
}

.call-to-action-content p {
  margin-bottom: 20px;
  text-align: center;
}

.call-to-action-content button {
  padding: 10px 20px;
  background-color: #007bff;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}

.call-to-action-content button:hover {
  background-color: #0056b3;
}

/* Overlay styling */
.overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
}

@keyframes gradientMove {
  0% {
      background-position: 0% 50%;
  }
  50% {
      background-position: 100% 50%;
  }
  100% {
      background-position: 0% 50%;
  }
}


/* Modal window styling */
.modal {
  background: white;
  padding: 20px;
  border-radius: 8px; /* Rounded corners */
  position: relative;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.modal-content {
  position: relative;
  padding: 20px;
}

/* Close button styling */
.close-button {
  position: absolute;
  top: 10px;
  right: 10px;
  border: none;
  background: none;
  cursor: pointer;
}

/* Call to Action button */
.cta-button {
  background-color: #007bff; /* Example blue */
  color: white;
  border: none;
  padding: 10px 20px;
  text-align: center;
  display: block;
  margin: 20px auto;
  border-radius: 5px; /* Rounded corners for the button */
  cursor: pointer;
}

.close-button:hover, .cta-button:hover {
  opacity: 0.8;
}
.file-tile-and-icon{
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}