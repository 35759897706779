.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
}

.modal-container {
  background: white;
  width: 40%;
  height: 45%; 
  max-width: 600px; /* New max-width for larger screens */
  min-width: 500px;
  min-height: 400px;
  border-radius: 10px;
  overflow: hidden;
  box-shadow: 0 4px 16px rgba(0, 0, 0, 0.2);
  display: flex; /* Ensure flex layout */
  flex-direction: column; /* Stack children vertically */
  position: relative; /* Added to serve as a positioning context for the absolute button */
}


.modal-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.modal-header h2 {
  margin: 0;
  font-weight: normal;
  color: #333;
}

.close-button {
  background: none;
  border: none;
  cursor: pointer;
  padding: 0;
  font-size: 24px;
  color: #000;
}

.modal-body {
  width: 100%;
  display: flex;
  justify-content:space-evenly; /* Center horizontally */
  align-items:flex-start;
  align-self: center;
  text-align: center;
  padding-left:20px;
  padding-right: 20px;
}



.upload-content, .text-content, .ai-content {
  display: flex;
  flex-direction: column;
  align-items: center;
  min-width: 90%;
  margin: auto;
}

.action-button, .map-button {
  background-color: #f471ff;
  color: white;
  border: none;
  padding: 10px 20px;
  border-radius: 20px;
  cursor: pointer;
  font-size: 16px;
  margin: 16px 0;
  width: 80%;
  max-width: 300px;
}

.map-button {
  background-color: #f471ff;
  color: white;
  border: none;
  padding: 10px 20px;
  border-radius: 20px;
  cursor: pointer;
  font-size: 16px;
  position: relative; /* Position the button absolutely within the modal-container */
  bottom: 5px; /* Distance from the bottom of the modal-container */
  left: 50%; /* Center horizontally */
  transform: translateX(-50%); /* Offset by half of its width to truly center it */
  width: auto;
  margin-top: auto; /* This can be removed as it's no longer necessary with absolute positioning */
}

.upload{
  display: flex;
  justify-content:center; /* Center horizontally */
  align-items:center;
  align-self: center;
  text-align: center;
}

.upload-button:hover, .map-button:hover {
  background-color: #f471ff;
}

.textarea-upload-modal {
  width: 100%; 
  max-width: 500px; 
  padding-bottom: 175px; 
  padding-left: 10px; 
  padding-right: 10px; 
  padding-top: 10px;
  margin-bottom: 16px;
  border-radius: 10px;
  border: 2px dashed #ccc; 
  min-height: 100px;
  max-height: 135px;
  margin-top: none;
  resize:none;
}

.generation-confirmation-ai-text{
  padding:20px;
  border-radius: 10px;
  border: 2px dashed #ccc; 
}

.text-input-normal{
  width: 100%; 
  max-width: 500px; 
  padding-bottom: 143px; 
  padding-left: 10px; 
  padding-right: 10px; 
  padding-top: 15px;
  margin-bottom: 16px;
  border-radius: 5px;
  border: 1.5px solid black;
  min-height: 100px;
  max-height:150px;
  margin-top: none;
  resize:none;
}

.ai-content-description{
  font-weight: 400;
  font-size: 20px;
}

.upload-content p {
  color: #666;
  font-size: 12px;
  margin-top: 16px;
}
.header-button {
  background: none;
  border: none;
  font-size: 16px;
  cursor: pointer;
  padding: 10px 20px;
  border-radius: 5px;
  margin-right: 10px; /* Spacing between buttons */
}

.header-button.active {
  background-color: #f2f2f2;
}
.recommend-text {
  color: #666;
  font-size: 14px;
  align-self: stretch; /* Stretch to match container width */
  text-align: left; /* Align the text to the left */
  margin-top: 8px;
  margin-left: 40px; /* Space from the button */
}

.modal-content {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
}
.modal-tabs {
  gap: 55px; /* Adjust the gap to match the design */
}

.header-tab {
  background: none;
  border: none;
  padding: 8px 16px; /* Adjust padding to match the design */
  cursor: pointer;
  font-size: 16px;
  color: #000;
  outline: none;
}

.header-tab.active {
  color: #555;
  border-bottom: 3px solid #555;
}

.tab-icon {
  height: 18px; /* Adjust the size as needed */
  width: 18px; /* Adjust the size as needed */
  margin-right: 8px; /* Space between icon and text */
}
.button-icon {
  height: 18px; /* Adjust the size as needed */
  width: 18px; /* Adjust the size as needed */
  margin-right: 8px; /* Space between icon and text */
}
@media (max-width: 1024px) {
  .modal-container {
    width: 70%; /* Slightly smaller on medium screens */
  }
}

@media (max-width: 768px) {
  .modal-container {
    max-width: 80%; /* Smaller on small screens */
    min-width: 300px;
  }
}


/* Large screens */
@media (min-width: 1200px) {
.modal-container {
  width: 50%;
  height: auto; /* Adjust height */
}
}

/* Medium screens */
@media (max-width: 1199px) {
.modal-container {
  width: 70%;
  height: auto; /* Adjust height */
}
}

/* Small screens */
@media (max-width: 767px) {

.ai-content{
  margin-bottom:10px;
}

  .file-uploader{
    padding-right: 0px;
    padding-left: 0px;
  }

  .close-button {
    display: none;
  }
.modal-container {
  width: 90%;
  min-width: 300px; /* Ensure modal is not too small */
  height: auto; /* Adjust height */
  padding: 20px; /* Adjust padding for smaller screens */
}

.modal-content {
  flex-direction: column; /* Stack content vertically on smaller screens */
}
}

/* Prevent side scroll and ensure content fits on smaller screens */
@media (max-width: 767px) {
body, .modal-overlay, .modal-container {
  overflow-x: hidden; /* Prevent horizontal scrolling */
}

.modal-container, .modal-content {
  box-sizing: border-box; /* Include padding and border in the element's total width and height */
}

.modal-container .tab-container, .modal-container .tab-content {
  max-width: 100%; /* Ensure tab containers do not overflow */
  padding: 0 10px; /* Adjust padding to ensure content fits within the screen */
  box-sizing: border-box;
}

.modal-container .tab {
  font-size: 14px; /* Adjust font size for smaller screens to ensure tabs fit */
  margin-right: 5px; /* Reduce margin between tabs */
  padding: 5px 10px; /* Adjust tab padding */
}

/* Ensure images and other content scale down or fit within their containers */
img, video, iframe, .content-item {
  max-width: 100%;
  height: auto; /* Scale height automatically to maintain aspect ratio */
}
}

/* Specific styles for iPhone 14 Pro screen size */
@media (max-width: 430px) {
.modal-container {
  padding: 15px; /* Reduced padding to increase available space for content */
  box-sizing: border-box; /* Include padding in width calculation */
}

.tab-container {
  display: flex;
  flex-wrap: wrap; /* Allow tabs to wrap to the next line */
  gap: 10px; /* Space between tabs when they wrap */
  margin: 0 -5px; /* Adjusting margin to align with the new padding */
  overflow: hidden; /* Prevent horizontal overflow */
  width: calc(100% + 10px); /* Adjust width to account for negative margins */
}

.tab {
  flex: 1 1 auto; /* Allow tabs to grow and shrink as needed */
  min-width: 80px; /* Minimum width for a tab to maintain its content */
  box-sizing: border-box;
  text-align: center; /* Center text within tabs for aesthetics */
}

.input-field, .submit-button {
  width: 100%; /* Full width to prevent overflow */
  box-sizing: border-box; /* Include padding and border in width calculation */
}

.submit-button {
  margin-top: 10px; /* Ensure spacing between input field and button */
}
}

/* Initially hide the dropdown */
.modal-dropdown {
  display: none;
}

/* Add this to your uploadmodal.css */
.text-input-error {
  animation: shake 0.5s ease-in-out, colorizeBorder 3s ease-in-out forwards;
  border-color: red; /* Initial color change, optional if using animation */
}

@keyframes shake {
  0% { transform: translateX(0); }
  25% { transform: translateX(-5px); }
  50% { transform: translateX(5px); }
  75% { transform: translateX(-5px); }
  100% { transform: translateX(0); }
}

@keyframes colorizeBorder {
  0% { border-color: red; }
  100% { border-color: initial; } /* Change to your default border color or remove for no color reset */
}


/* Hide tabs and show dropdown on smaller screens */
@media (max-width: 767px) {
  .modal-tabs {
    display: none;
  }
  .modal-dropdown {
    display: block;
    width: 80%; /* Example: Set this to your desired width */
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 15px; /* Add some space below the dropdown */
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 5px;
  }
}

/* Larger screens: Show tabs */
@media (min-width: 768px) {
  .modal-tabs {
    display: flex;
  }
  .modal-dropdown {
    display: none;
  }
}

.map-type-selector select {
  width: 100%;
  padding: 10px;
  margin:10px;
  border: 1px solid #ccc;
  border-radius: 5px;
  font-size: 16px;
  margin-bottom: 16px;
}

.map-type-selector {
  width: 80%;
}



.modal-container {
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: 600px;
  background-color: white;
  border-radius: 8px;
  overflow: hidden;
  position: relative;
}

.header-actions {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
  padding-top: 16px;
  padding-bottom: 0px;
}

.close-button {
  background: none;
  border: none;
  font-size: 24px;
  cursor: pointer;
}

.map-type-selector select {
  padding: 8px;
  border-radius: 4px;
  border: 1px solid #ccc;
}

.modal-header {
  display: flex;
  flex-direction: column;
  padding-left:16px;
  padding-right:16px;
}

.modal-dropdown {
  display: none;
}

@media (max-width: 600px) {
  .modal-tabs {
      display: none;
  }

  .modal-dropdown {
      display: block;
      margin-bottom: 16px;
  }
}

.chart-info{
  color:#555;
  font-size: 15px;
}
