.file-uploader-wrapper {
  display: flex;
  justify-content: center;
  flex-direction: column;

  min-width: 100%;
  /*height: 80vh;*/ /* This assumes you want the uploader to be centered in the viewport */
}

.file-uploader {
  display: flex;
  justify-content: center;
  flex-direction: column; /* Stack the child elements vertically */
  /*height: 30%;*/
  width: 60%; /* Adjust the width as needed */
  background-color: white;
  transition: background-color 0.3s ease;
  cursor: pointer;
  margin: auto;
}

.file-uploader-description{
  color:grey;
  font-weight:300;
}

.file-uploader.drag-over {
  background-color: #f0f0f0;
}

/* Style for the content inside the file uploader */
.file-uploader-content {
  text-align: center;
  max-width: 480px; /* Maximum width */
  margin: 0 auto; /* Center the content */
}
.file-uploader-alert {
  text-align: center;
  color: #d9534f; /* Red color for alert */
  margin-bottom: 15px;
}

.file-uploader-alert-icon {
  font-size: 24px;
  margin-bottom: 8px;
}

.file-uploader-instructions {
  position: relative;
  padding-bottom: 40px;
  background-color: #f471ff;
  color: white;
  border: none;
  padding: 10px 20px;
  border-radius: 20px;
  cursor: pointer;
  font-size: 16px;
  margin: 16px 0;
  width: 100%;
  max-width: 300px;
}

.file-uploader-details {
  font-size: 14px;
  color: #6c757d; /* Light grey color for additional details */
}

.file-uploader-filename {
  font-size: 16px;
  font-weight: bold;
  color: #28a745; /* Green color for filename */
  margin-top: 15px;
}
.confirmation-modal-overlay{
  padding:20px;
}

.calculating-credits-loader {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 20px;
}

.calculating-credits-loader span {
  margin-left: 10px;
  font-weight: bold;
}

/* Mobile responsiveness */
@media (max-width: 600px) {
  .file-uploader-content {
    padding: 15px;
    max-width: 100%;
  }
}


.custom-instructions-container {
  align-items: center;
  text-align: left;
  justify-content: space-around;
  padding: 20px;
  border: 1px solid #ccc; /* Optional border */
  border-radius: 8px; /* Optional rounded corners */
  margin: 20px;
  box-shadow: 0 2px 4px rgba(0,0,0,0.1); /* Optional shadow */
  font: Lato, sans-serif;
}


.row-section{
  display: flex;
}


.button-section{
  display: flex;
  flex-direction: row;
  justify-content:flex-end;
}

.image-section{
  width: 100%;
  margin-right: -150px;
}

.image-section img {
  width: 50%;
  height: auto;
}

.yes-btn{
  padding: 10px 20px;
  margin: 10px 5px 0 0;
  border: none;
  border-radius: 20px;
  cursor: pointer;
  font-weight: bold;
  width: 200px;
  font-size: smaller;
  background-color: #f286fbd1;
}

.yes-btn:hover {
  background-color: #f471ff;
  color: white;
}

.no-btn {
  font-size: smaller;
  background-color: transparent;
  padding: 10px 20px;
  margin: 10px 5px 0 0;
  border: none;
  border-radius: 20px;
  cursor: pointer;
  font-weight: lighter;
  width: 200px;
}

.no-btn:hover {
  background-color: #f0f0f0;
}


.map-type-selection {
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 10px;
  max-width: 600px;
  margin: 0 auto;
}

.map-type-selection h3 {
  font-size: 24px;
  color: #333;
  margin-bottom: 20px;
  font-weight: 600;
}

.map-type-options {
  display: flex;
  gap: 15px;
}

.map-type-options button {
  background-color: #ffffff;
  color: #333;
  border: 2px solid #d1d5db;
  border-radius: 20px;
  padding: 12px 10px;
  cursor: pointer;
  transition: all 0.3s ease;
  font-size: 16px;
  font-weight: 500;
}

.map-type-options button:hover {
  background-color: #e5e7eb;
  border-color: #cbd5e0;
}

.map-type-options button:focus, 
.map-type-options button.selected {
  background-color: #333333;
  color: #fff;
  border-color: #333333;
}

.map-type-image {
  margin-top: 25px;
  margin-bottom: 50px;  
  padding:20px;
}

.map-type-image img {
  width: auto; /* Maintain aspect ratio */
  max-height: 200px; /* Ensure the height does not exceed 250px */
  border-radius: 8px;
  transition: transform 10s ease; /* Smooth zoom over 10 seconds */
}

.zoomed {
  transform: scale(1.2); /* Zoom-in effect */
}


.map-type-selection.active {
  background-color: #333333; /* Example highlight color */
  color: white;
  border-color: #333333;
}

.map-description-container {
  margin-top: 1rem;
  text-align: center;
}

.map-description {
  font-size: 1rem;
  color: #666;
  max-width: 600px;
  margin: 0 auto; /* Center the description */
}