/* General container styles */
.wall-of-love-container {
    overflow:hidden;
    text-align: center;
    max-width: 100%; /* Adjust as needed for your layout */
    margin: 0 auto;
    padding: 2rem;
  }
  
  /* Heading styles */
  .wall-of-love-heading {
    font-size: 3rem; /* Large, readable text */
    color: #333; /* Dark text for contrast */
    margin-bottom: 0.5rem;
  }
  
  /* Description paragraph styles */
  .wall-of-love-description {
    font-size: 1rem;
    color: #666; /* Slightly lighter text */
    margin-bottom: 40px;
  }
  
  
  /* Call-to-action heading */
  .wall-of-love-cta-heading {
    font-size: 40px;
    color: #333;
    margin-top: 40px;
  }

  .highlight-mindmap.counter{
    font-size: 40px;
    padding:10px;
  }

  .highlight-pdf.wall-of-love{
    padding:10px;
    border:none;
  }

  
  /* You might want to add some responsive design features */
  @media (max-width: 768px) {
    .wall-of-love-heading {
      font-size: 1.5rem;
    }
  
    .wall-of-love-cta-heading {
      font-size: 1.25rem;
      line-height: 2;
    }
  }
  