.sidebar {
    position: fixed;
    width: 250px;
    background-color: #333;
    color: white;
    display: flex;
    height: 95vh;
    flex-direction: column;
    padding: 2em;
    padding-bottom: 50px;
    font-family: 'Lato', sans-serif;
    font-weight: lighter;
    z-index: 100;
  }

  .modal-content{
    background: transparent;
    border:none;
  }

  .modal-backdrop.show {
    background-color: rgba(0, 0, 0, 1); /* Make backdrop darker */
}

  .tooltip-container {
    position: relative;
    display: inline-flex;
    align-items: center;
    cursor: pointer;
  }
  
  .tooltip-icon {
    margin-left: 8px; /* Adjust as necessary */
  }
  
  .tooltip-text {
    visibility: hidden;
    width: 200px;
    background-color: #333; /* Adjust the color to match your theme */
    color: #fff;
    text-align: center;
    border-radius: 6px;
    padding: 8px;
    position: absolute;
    z-index: 20000;
    bottom: -250%; /* Adjust as necessary */
    left: 50%;
    transform: translateX(-50%);
    font-size: 0.875rem; /* Adjust as necessary */
    font-weight: 100;
    box-shadow: 0 2px 4px rgba(0,0,0,0.2); /* Optional: Adds shadow for depth */
  }
  
  .tooltip-container:hover .tooltip-text {
    visibility: visible;
  }
  .logo {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: left;
    gap: 0.5em;
    margin-bottom: 5em;
  }

.logo img {
    width: 100%; /* Makes the image take up 100% of the .logo div's width */
    height: auto; /* Maintains the aspect ratio of the image */
    max-width: 180px; /* Optionally limits the size to prevent it from being too large */
    max-height: 60px; /* Adjust max-height as needed */
  }

  .logo:hover {
    cursor: pointer;
  }
  
  
  .nav {
    display: flex;
    flex-direction: column;
  }
  
  .nav-item {
    text-decoration: none;
    color: white;
    margin-bottom: 0.5em;
    display: flex;
    align-items: center;
    gap: 1em;
    border: none;
    background: none;
    padding: 10px 20px;
    cursor: pointer;
  }
  
  /* Hover effect for nav items */
.nav-item:hover {
  background-color: #555; /* Darken the item on hover */
}

/* Visual indication for the selected nav item */
.nav-item.active {
  background-color: #666; /* Different background for active item */
}

/* Hover effect for support and sign out */
.support:hover, .nav-item:last-child:hover {
  background-color: #555; /* Darken the item on hover */
}

/* Click effect for all buttons, including support and sign out */
.nav-item:active, .support:active {
  transform: scale(0.98); /* Slightly shrink the item to simulate a click */
}

  .nav-item svg {
    font-size: 1.2em; /* Adjust the size as needed */
  }
  
  .upgrade-btn {
    background-color: #ff69b4;
    color: white;
    padding: 0.5em 1em;
    border: none;
    border-radius: 20px;
    margin-top: 2em;
    cursor: pointer;
  }

  .upgrade-btn button:hover {
    background-color: #ff4d9e;
  }
  
  .upgrade-btn button:focus {
    outline: none;
    box-shadow: 0 0 0 2px #ff69b4;
  }
  
  .upgrade-btn button:active {
    transform: scale(0.95);
  }
  
  
  .support {
    margin-top: auto; /* Pushes the support to the bottom */
  }

  .hamburger-menu {
    display: none;
  }

/* On mobile devices */
@media (max-width: 768px) {
  .signout{
    padding-bottom: 100px;
  }
  .sidebar {
    width: 250px; /* Adjust width as needed */
    position: fixed; /* Fixed position to make it float over content */
    top: 0;
    left: -250px; /* Start off-screen */
    background-color: #333; /* Adjust background color as needed */
    padding: 2em;
    visibility: hidden;
    transition: transform 0.3s ease-in-out; /* Smooth slide in/out */
    z-index: 1001; /* Ensure it's above other content */
  }

  .sidebar.open {
    transform: translateX(250px); /* Slide in */
    visibility: visible;
  }
  
    .hamburger-menu.open {
      display: block;
      position: fixed;
      top: 15px;
      left: 15px;;
      z-index: 1000;
    }
  }

  /* Initially hide the close button */
.close-btn {
  display: none;
}

/* Only show the close button on small screens when the sidebar is visible */
@media (max-width: 768px) {
  .sidebar.open .close-btn {
    display: flex; /* or "block", depending on your layout */
    position: fixed; /* Adjust positioning as needed */
    top: 15px; /* Example positioning */
    right: 10px; /* Adjust this value based on your sidebar's layout */
    z-index: 1050; /* Ensure it's above the sidebar */
    /* Add additional styling as needed */
  }
}
