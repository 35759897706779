/* General Styles */
.modal-backdrop {
    position: fixed;
    top: 0px;
    left: 0;
    width: 100vw;
    height: 100vh;
    background-color: rgba(0, 0, 0, 0.5); /* Dark semi-transparent background */
    z-index: 1050; /* Above content, below modal */
    display: none; /* Start hidden, shown with 'show' class */
  }


  .error-message-form {
    color: black;
    margin-top: 5px;
  }

  .popup-message {
    background-color: #fff;
    border-radius: 4px;
    box-shadow: 0 4px 8px #0000001a;
    font-size: larger;
    font-weight: 600;
    margin-bottom: 8px;
    padding: 16px;
}

.popup-container {
  bottom: 16px;
  max-width: 320px;
  position: fixed;
  right: 16px;
  z-index: 1200;
}

.start-button {
  background-color: #000;
  border: none;
  border-radius: 8px;
  color: #fff;
  cursor: pointer;
  font-size: 20px;
  font-size: larger;
  font-weight: 400;
  margin-top: 20px;
  padding: 4px 16px;
}

  .thank-you-heading{
    text-align: center;
    color: black;
    font-size: 40px;
    padding-bottom: 10px;
    font-weight: bolder;
  }

  .thank-you-description{
    text-align: center;
    color: #6c757d;
    font-size: larger;
    font-weight:lighter;
    padding-bottom: 10px;
  }
  
  .modal-backdrop.show {
    display: block; /* Show backdrop when 'show' class is added */
  }
  
  /* Centering the form container */
  .form-container {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background: #fff;
    padding: 20px;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    z-index: 1060; /* Above the backdrop */
    width: 70%; /* Use percentage for better responsive design */
    max-width: 600px; /* Maximum width of the container */
    padding-bottom:50px;
  }
  
  /* Form styles */
  .my-form {
    display: flex;
    flex-direction: column;
  }

  .question-page h2{
    padding-top: 20px;
    padding-bottom: 20px;
    text-align: center;
    font-weight: bolder;
  }

  .question-page p{
    padding-bottom: 20px;
    text-align: center;
    font-weight: 600;
    color: #6c757d;
    font-size: larger;
  }
  
  .my-form label {
    margin-top: 10px;
  }

  
  /* Apply similar styles to all buttons, differentiate with additional classes */
.next-button, .submit-button {
    background-color: #edb4c4ff; /* Primary button color */
  }
  
  .prev-button {
    background-color: #6c757d; /* Different color for 'Previous' button */
    height: 34px;
    align-self: center;
  }
  .prev-button{
    margin-top: 20px;
    padding-left:0px;
    padding-right:0px;
    padding-top: 0px;
    padding-bottom: 0px;
  }
  
/* Hover effect for all buttons */
button:hover {
    opacity: 0.8;
  }
  
  /* Button styles */
 .next-button {
    margin: 5px;
  }


  
  .submit-button {
    margin-top: 20px;
  }
  
  /* Option button styles */
  .option-button {
    display: block;
    background: #F7F7F7;
    text-align: center;
    font-weight: bold;
    color: #000;
    margin-bottom: 10px;
    padding: 10px 15px;
    cursor: pointer;
    border-radius: 10px;
    transition: all 0.3s ease;
  }
  
  .option-button.selected {
    background:#edb4c4ff;
    color: white;
  }
  
  /* Input field for 'Other' option */
  .other-input {
    display: block;
    width: 100%;
    padding: 10px;
    margin-top: 10px;
    border-radius: 5px;
    border: 1px solid #DADADA;
  }
  
  /* Navigation buttons container */
  .navigation-buttons {
    display: flex;
    justify-content: right;
    margin-top: 20px;
    gap: 10px;
  }
  
  /* Responsive styles */
  @media (max-width: 768px) {
    /* Styles for tablets and below */
    .form-container {
      width: 95%; /* Slightly smaller width on smaller screens */
      box-shadow: 0 2px 10px rgba(0, 0, 0, 0.2); /* Softer shadow */
    }
  
    .option-button, .other-input {
      padding: 8px 15px; /* Slightly smaller padding on smaller screens */
    }
  
    .prev-button, .next-button, .submit-button {
      width: 48%; /* Make navigation buttons half-width on mobile */
    }
  }
  
  @media (max-width: 480px) {
    /* Styles for mobile phones */
    .navigation-buttons {
      flex-direction: column; /* Stack navigation buttons vertically on smaller screens */
    }
  
    .prev-button, .next-button, .submit-button {
      width: 100%; /* Full width buttons on very small screens */
      margin-top: 10px; /* Add margin top for spacing */
    }
  }

  .prev-button, .next-button, .submit-button {
    padding-left:10px;
    padding-right:10px;
    min-height: 40px;
    border: none;
    border-radius: 6px;
  }

  .prev-button{
    color: white;
  }
  