.use-cases-wrapper {
    margin: 20px;
    text-align: center;
  }
  
  .use-cases-heading {
    font-size: 48px;
    margin-bottom: 20px;
    font-weight: bold;
    padding-bottom:100px;
  }
  
  .use-cases-container {
    display: flex;
    overflow-x: auto;
    white-space: nowrap;
    padding: 10px;
    scrollbar-width: none; /* Hide scrollbar in Firefox */
    -ms-overflow-style: none;  /* Hide scrollbar in IE/Edge */
  }
  
  .use-cases-container::-webkit-scrollbar {
    display: none; /* Hide scrollbar in Chrome/Safari */
  }
  
  .use-case-card {
    display: flex;
    flex-direction: column;
    align-items: center; 
    justify-content: space-between; 
    align-items:flex-start; /* Align items to the top of the card */
    min-width: 350px;
    max-width: 350px;
    padding: 20px;
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    background-color: #fffdfa;
    text-align: left;
    margin:20px;
  }
  
.image-container{
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    align-items: flex-end;
  }
  .use-case-image {
    width: 50px; /* Adjust width as needed */
    height: auto;
    border-radius: 8px;
  }
  
  .heading-container {
    display: flex; 
    align-items: center; 
    justify-content: flex-start; /* Aligns heading to the left */
    height: 100%; 
  }
  
  .use-case-heading {
    font-size: 1.5em;
    margin: 10px 0;
    color: #333;
    text-align: left; /* Ensure the heading text inside the card is aligned to the left */
  }
  
  
  
  .use-case-description {
    font-size: 1em;
    color: #666;
    margin-bottom: 20px;
    line-height: 1.5;
    word-wrap: break-word; /* Ensures long words or strings break */
    white-space: normal; /* Allows automatic line breaks */
    overflow-wrap: anywhere; /* Breaks overly long strings/words */
  }
  
  .try-now-button {
    padding: 10px 20px;
    color:black;
    border-radius: 5px;
    font-size: large;
    text-decoration: underline black 2px;
    margin-left:70%;
  }

  
  .try-now-button:hover {
    color:#3d3d3d;
  }
  
  