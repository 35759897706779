.mindmap-survey-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1000;
}

.mindmap-survey-container {
  background-color: white;
  border-radius: 8px;
  width: 90%;
  max-width: 400px;
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.15);
  overflow: hidden;
  animation: mindmapModalSlide 0.3s ease-out;
}

.mindmap-survey-header {
  background: linear-gradient(to right, #e6d5e6, #ffccd5);
  padding: 24px;
}

.mindmap-survey-header h2 {
  margin: 0;
  color: #1a3a3a;
  font-size: 24px;
  font-weight: bold;
}

.mindmap-survey-content {
  padding: 24px;
}

.mindmap-survey-content p {
  margin: 0 0 24px 0;
  color: #4a4a4a;
  line-height: 1.5;
}

.mindmap-survey-buttons {
  display: flex;
  flex-direction: column;
  gap: 12px;
}

.mindmap-survey-primary-btn {
  background-color: #1a3a3a;
  color: white;
  border: none;
  border-radius: 4px;
  padding: 12px 24px;
  font-weight: 500;
  cursor: pointer;
  transition: background-color 0.2s;
}

.mindmap-survey-primary-btn:hover {
  background-color: #254545;
  color: black;
}

.mindmap-survey-secondary-btn {
  background-color: white;
  border: 1px solid #e0e0e0;
  border-radius: 4px;
  padding: 12px 24px;
  cursor: pointer;
  transition: background-color 0.2s;
}

.mindmap-survey-secondary-btn:hover {
  background-color: #f5f5f5;
}

.mindmap-ratings-container {
  display: flex;
  justify-content: flex-start;
}

.mindmap-rating-btn {
  position: relative;
  background: none;
  border: none;
  cursor: pointer;
  border-radius: 4px;
  transition: background-color 0.2s;
}

.mindmap-rating-btn:hover {
  background-color: #f5f5f5;
}

.mindmap-rating-btn:disabled {
  opacity: 0.5;
  cursor: not-allowed;
}

/* New tooltip styles with unique class names */
.mindmap-rating-tooltip-text {
  visibility: hidden;
  width: 150px;
  background-color: black;
  color: white;
  text-align: center;
  border-radius: 6px;
  padding: 5px 0;
  font-weight: 300;
  position: absolute;
  z-index: 1;
  bottom: 140%;  /* Changed from bottom: -120% to position it above */
  left: 50%;
  transform: translateX(-50%);  /* Better centering */
  opacity: 0;
  transition: opacity 0.3s;
}

.mindmap-rating-block {
  position: relative;
  display: inline-block;
}

.mindmap-like-block:hover .mindmap-rating-tooltip-text,
.mindmap-dislike-block:hover .mindmap-rating-tooltip-text {
  visibility: visible;
  opacity: 1;
}

.mindmap-like-block .mindmap-rating-tooltip-text::after,
.mindmap-dislike-block .mindmap-rating-tooltip-text::after {
  content: "";
  position: absolute;
  bottom: 100%;
  left: 50%;
  margin-left: -5px;
  border-width: 5px;
  border-style: solid;
  border-color: transparent transparent black transparent;
}

.mindmap-rating-tooltip {
  position: absolute;
  top: 40px;
  left: 50%;
  transform: translateX(-50%);
  background-color: #333;
  color: white;
  padding: 4px 8px;
  border-radius: 4px;
  font-size: 12px;
  white-space: nowrap;
  opacity: 0;
  transition: opacity 0.2s;
  pointer-events: none;
}

.mindmap-rating-btn:hover .mindmap-rating-tooltip {
  opacity: 1;
}

@keyframes mindmapModalSlide {
  from {
    transform: translateY(-20px);
    opacity: 0;
  }
  to {
    transform: translateY(0);
    opacity: 1;
  }
}