/* RenameModal.css */
.rename-modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 2000;
  }
  
  .rename-modal {
    background: #FFFFFF;
    border-radius: 12px; /* Increased radius */
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.15); /* Softer shadow */
    width: 500px; /* Fixed width instead of percentage for consistency */
    padding: 32px; /* Increased padding */
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
  }
  
  .rename-modal-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 24px;
  }
  
  .rename-modal-title {
    font-size: 1.5rem; /* Increased font size */
    font-weight: 600; /* Make font bolder */
    color: #000000; /* Black color text */
    }
  
  .rename-modal-close-button {
    font-size: 1.2rem;
    color: #333333;
    cursor: pointer;
    border: none;
    background: none;
  }
  
  .rename-modal-form {
    display: flex;
    flex-direction: column;
    gap: 10px;
  }
  
  .rename-modal-label {
    font-size: 1.1rem; /* Slightly larger font size */
    color: #000000; /* Black color text */
    font-weight: normal; /* Ensure normal font weight */
  }
  
  .rename-modal-input {
    display: block; /* Ensure the input is not inline */
    width: calc(100% - 24px); /* Subtracting the total horizontal padding from the width */
    height: 32px; /* Fixed height */
    margin: 0; /* Reset any default margins */
    padding: 8px 12px; /* Top and bottom padding 8px, left and right padding 12px */
    font-size: 1rem; /* Standard font size */
    border: 2px solid #DADCE0; /* Subtle border color */
    border-radius: 8px; /* Rounded corners */
    box-sizing: border-box; /* Include padding and border in the element's total width and height */
    outline: none; /* No outline on focus */
    min-height: 20px;
  }
  
  .rename-modal-input:focus {
    border-color: #4D90FE; /* Highlight color on focus */
    box-shadow: 0 0 0 3px rgba(66,133,244,0.5); /* Halo effect on focus */
  }
  
  .rename-modal-actions {
    display: flex;
    justify-content: flex-end;
    gap: 10px;
    margin-top: 20px;
  }
  
  .rename-modal-button {
    padding: 10px 20px;
    border: none;
    border-radius: 4px;
    font-size: 1rem;
    cursor: pointer;
    transition: background-color 0.3s;
    min-width: 90px; /* Match the button width in the image */
    box-shadow: none; /* Remove box shadow */
    text-transform: none;
  }
  
  .rename-modal-button-ok {
    background-color: #4D90FE; /* Matching the blue color from the image */
    color: white;
  }
  
  .rename-modal-button-ok:hover {
    background-color: #357AE8; /* Darker blue on hover */
  }
  
  .rename-modal-button-cancel {
    background-color: #ffffff; /* White background */
    color: #757575; /* Dim color for text */
    border: 1px solid #B6B6B6;
  }
  
  .rename-modal-button-cancel:hover {
    background-color: #e0e0e0; /* Slightly darker grey on hover */
  }
  .rename-modal-input.empty {
    border: 1px solid red;
    animation: shake 0.5s;
  }
  .rename-modal-button.disabled {
    background-color: #ccc;
    color: #666;
    cursor: not-allowed;
  }
  
  @keyframes shake {
    0% { margin-left: 0px; }
    25% { margin-left: 5px; }
    75% { margin-left: -5px; }
    100% { margin-left: 0px; }
  }
  
  @media (max-width: 768px) {
    .rename-modal {
        width: calc(100% - 48px);
    }
  }
  
  @media (max-width: 480px) {
    .rename-modal {
        width: calc(100% - 30px);
        padding: 20px;
    }
  
    .rename-modal-title {
      font-size: 1rem;
    }
  
    .rename-modal-button {
      padding: 8px 16px;
      font-size: 0.9rem;
    }
      
  }
  .input-invalid {
    border-color: 1px solid red;
    animation: shake 0.5s;
  }
  
  @keyframes shake {
    0%, 100% { transform: translateX(0); }
    25% { transform: translateX(-5px); }
    75% { transform: translateX(5px); }
  }
  
  