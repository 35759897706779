@import url('https://fonts.googleapis.com/css2?family=Inter:wght@400;500;600;700&display=swap');

#banner { 
    display: flex;
    width: 100%;
    padding: 20px 32px;
    background: linear-gradient(135deg, #0f0f0f 0%, #1a1a1a 100%);
    color: #ffffff;
    box-sizing: border-box;
    font-family: 'Inter', sans-serif;
    opacity: 0;
    transform: translateY(-20px);
    transition: all 0.5s ease-out;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

#banner.visible {
    opacity: 1;
    transform: translateY(0);
}

.banner-content {
    display: grid;
    grid-template-columns: 1fr 2fr 1fr;
    align-items: center;
    gap: 32px;
    margin: 0 auto;
    max-width: 1400px;
    width: 100%;
}

.left-content {
    display: flex;
    flex-direction: column;
    gap: 12px;
}

.timer-container {
    display: flex;
    align-items: center;
    gap: 8px;
}

.time-unit {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 4px;
}

.time-value {
    font-size: 24px;
    font-weight: 700;
    color: #ffffff;
    background: rgba(255, 255, 255, 0.1);
    padding: 8px 12px;
    border-radius: 8px;
    min-width: 40px;
    text-align: center;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
}

.time-label {
    font-size: 12px;
    font-weight: 500;
    color: rgba(255, 255, 255, 0.7);
    text-transform: uppercase;
    letter-spacing: 0.5px;
}

.time-separator {
    font-size: 24px;
    font-weight: 700;
    margin: 0 4px;
    color: rgba(255, 255, 255, 0.5);
}

.sale-badge {
    font-size: 12px;
    font-weight: 600;
    background: linear-gradient(135deg, #ff0000 0%, #ff4444 100%);
    padding: 6px 12px;
    border-radius: 20px;
    display: inline-flex;
    align-items: center;
    gap: 8px;
    width: fit-content;
    text-transform: uppercase;
    letter-spacing: 0.5px;
    box-shadow: 0 2px 4px rgba(255, 0, 0, 0.2);
    position: relative;
}

.badge-icon {
    width: 16px;
    height: 16px;
}

.sale-pulse {
    width: 8px;
    height: 8px;
    background-color: #ffffff;
    border-radius: 50%;
    display: inline-block;
    animation: pulse 2s infinite;
}

@keyframes pulse {
    0% {
        transform: scale(0.95);
        box-shadow: 0 0 0 0 rgba(255, 255, 255, 0.7);
    }
    70% {
        transform: scale(1);
        box-shadow: 0 0 0 6px rgba(255, 255, 255, 0);
    }
    100% {
        transform: scale(0.95);
        box-shadow: 0 0 0 0 rgba(255, 255, 255, 0);
    }
}

.center-content {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 8px;
}

.message {
    font-size: 24px;
    font-weight: 700;
    letter-spacing: 0.2px;
    text-align: center;
    line-height: 1.4;
}

.sale-title {
    font-size: 28px;
    font-weight: 800;
    background: linear-gradient(45deg, #ffffff 0%, rgba(255, 255, 255, 0.8) 100%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    text-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    margin-bottom: 4px;
    display: block;
}

.discount-text {
    font-size: 18px;
    margin-top: 4px;
}

.highlight {
    color: #ff4444;
    position: relative;
    display: inline-block;
    font-weight: 800;
}

.highlight::after {
    content: '';
    position: absolute;
    bottom: -2px;
    left: 0;
    width: 100%;
    height: 2px;
    background-color: #ff4444;
    transform: scaleX(0);
    transition: transform 0.3s ease;
}

.highlight:hover::after {
    transform: scaleX(1);
}

.right-content {
    display: flex;
    justify-content: flex-end;
    align-items: center;
}

.coupon-container {
    display: flex;
    align-items: center;
    gap: 8px;
    position: relative;
}

.coupon-code-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 6px;
}

.coupon-code {
    padding: 12px 20px;
    background: rgba(255, 255, 255, 0.1);
    border: 1px solid rgba(255, 255, 255, 0.2);
    border-radius: 8px;
    font-family: 'Inter', monospace;
    font-size: 20px;
    font-weight: 600;
    letter-spacing: 1.5px;
    cursor: pointer;
    transition: all 0.3s ease;
    user-select: all;
    position: relative;
    overflow: hidden;
}

.coupon-code:hover {
    background: rgba(255, 255, 255, 0.15);
    transform: translateY(-1px);
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}

.coupon-code.copied {
    background: rgba(39, 174, 96, 0.2);
    border-color: rgba(39, 174, 96, 0.4);
    animation: success-pulse 0.5s ease-in-out;
}

.code-text {
    position: relative;
    z-index: 1;
}

.copy-message {
    font-size: 13px;
    font-weight: 500;
    opacity: 0;
    transition: all 0.3s ease;
    white-space: nowrap;
    color: rgba(255, 255, 255, 0.9);
}

.show-message {
    opacity: 1;
}

.offer-details {
    font-size: 11px;
    background: linear-gradient(90deg, rgba(255, 255, 255, 0.1), rgba(255, 255, 255, 0.05));
    padding: 4px 12px;
    border-radius: 4px;
    color: rgba(255, 255, 255, 0.8);
    letter-spacing: 1px;
    font-weight: 500;
    display: inline-block;
    margin-top: 8px;
    border: 1px solid rgba(255, 255, 255, 0.1);
    backdrop-filter: blur(4px);
    transition: all 0.3s ease;
    cursor: pointer;
}

.offer-details:hover {
    background: rgba(255, 255, 255, 0.15);
    transform: translateY(-1px);
}

/* Theme Styles */
.early-bf-theme {
    background: linear-gradient(135deg, #0f0f0f 0%, #1a1a1a 100%);
}

.early-bf-theme .sale-badge {
    background: linear-gradient(135deg, #ff0000 0%, #ff4444 100%);
}

.early-bf-theme .highlight {
    color: #ff4444;
}

.bf-theme {
    background: linear-gradient(135deg, #000000 0%, #1a1a1a 100%);
}

.bf-theme .sale-badge {
    background: linear-gradient(135deg, #000000 0%, #333333 100%);
    border: 1px solid #ff0000;
}

.bf-theme .highlight {
    color: #ffffff;
    text-shadow: 0 0 10px rgba(255, 255, 255, 0.5);
}

.bf-theme .time-value {
    background: rgba(255, 0, 0, 0.1);
    border: 1px solid rgba(255, 0, 0, 0.2);
}

.cyber-theme {
    background: linear-gradient(135deg, #000033 0%, #000066 100%);
}

.cyber-theme .sale-badge {
    background: linear-gradient(135deg, #0066ff 0%, #00ccff 100%);
}

.cyber-theme .highlight {
    color: #00ccff;
}

.cyber-theme .time-value {
    background: rgba(0, 204, 255, 0.1);
    border: 1px solid rgba(0, 204, 255, 0.2);
}

.cyber-theme .coupon-code {
    background: rgba(0, 204, 255, 0.1);
    border: 1px solid rgba(0, 204, 255, 0.2);
}

/* Animations */
@keyframes success-pulse {
    0% { transform: scale(1); }
    50% { transform: scale(1.1); }
    100% { transform: scale(1); }
}

/* Responsive Design */
@media (max-width: 1024px) {
    .banner-content {
        gap: 20px;
    }

    .time-value {
        font-size: 20px;
        min-width: 36px;
        padding: 6px 10px;
    }

    .sale-title {
        font-size: 24px;
    }
}

@media (max-width: 768px) {
    #banner {
        padding: 20px 16px;
    }

    .banner-content {
        grid-template-columns: 1fr;
        gap: 24px;
        text-align: center;
    }
    
    .left-content {
        align-items: center;
    }
    
    .right-content {
        justify-content: center;
    }
    
    .timer-container {
        justify-content: center;
        flex-wrap: wrap;
    }
    
    .time-value {
        font-size: 18px;
        min-width: 32px;
        padding: 4px 8px;
    }

    .time-label {
        font-size: 10px;
    }
    
    .message {
        font-size: 20px;
    }
    
    .discount-text {
        font-size: 16px;
    }

    .coupon-code {
        font-size: 18px;
        padding: 10px 16px;
    }
}

@media (max-width: 480px) {
    .timer-container {
        gap: 4px;
    }

    .sale-title {
        font-size: 20px;
    }

    .offer-details {
        font-size: 10px;
        padding: 3px 8px;
    }
}