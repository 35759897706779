.edit-modal-overlay {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  width: 40%;
  z-index: 2000;
}

.title-of-map {
  display: flex;
  flex-direction: column;
  background-color: white;
  text-align: center;
  padding-bottom: 20px;
  padding-top: 20px;
  position: sticky;
  top: 0;
  left:0;
  z-index: 2000;
}

.node-summary-modal{
  z-index: 2001;
}

.editable-quote-bubble input[type="text"] {
  background-color: #e2e2e2;
  text-overflow: inherit;
  border-radius: 10px;
  border: none;
  padding: 10px;
  max-height: fit-content;
  width: 100%;
  margin-bottom: 10px;
}
.editable-quote-bubble input[type="text"]:focus {
  background-color: rgb(95, 95, 95);
  text-overflow: inherit;
  border-radius: 10px;
  border: none;
  padding: 10px;
  color:white;
  max-height: fit-content;
  width: 100%;
  margin-bottom: 10px;
}



.edit-modal-buttons {
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  background-color: white;
  position: fixed;
  bottom: 0px;
  padding-top: 20px;
  width: 40%;
  padding-bottom: 20px;
}

.edit-modal-buttons button {
  padding: 10px 20px;
  margin-right: 10px;
  border: none;
  width: 150px;
  border-radius: 24px;
  cursor: pointer;
  transition: background-color 0.3s;
}

.edit-modal-buttons button:disabled {
  background-color: #ccc;
  cursor: default;
}

.edit-modal-buttons button.save-button {
  background-color: #ff69b4ff;
  color: white;
}

.edit-modal-buttons button.save-button:hover {
  background-color: #e85aa1ff;
}

.edit-modal-buttons button.cancel-button {
  background-color: grey;
  color: white;
}

.edit-modal-buttons button.cancel-button:hover {
  background-color: rgb(89, 87, 87);
}

.editing-modal {
  background: white;
  width: 100%;
  height: 100vh;
  overflow: auto;
  box-shadow: -2px 0 5px rgba(0, 0, 0, 0.2);
  display: flex;
  flex-direction: column;
  position: relative;
  padding: 20px;
  padding-top:0px;
  padding-bottom: 20px;
}



.indent-level-1 {
  /* Styles for quotes with one level of indentation */
  font-size: 18px;
  margin-bottom: 20px;
  font-weight: 800;
  margin-left: 0px;
}



.indent-level-2 {
  /* Styles for quotes with two levels of indentation */
  font-size: 16px;
  font-style: italic;
  margin-left: 20px;
  margin-top: 20px;
}

.indent-level-3{

  margin-left: 40px;
  line-height: 50%;
}

.indent-level-4{

  margin-left: 60px;
}

.indent-level-5{

  margin-left: 80px;
}

.indent-level-6{

  margin-left: 100px;
}

.indent-level-7{

  margin-left: 120px;
}

.indent-level-8{

  margin-left: 140px;
}

.indent-level-9{

  margin-left: 160px;
}

.indent-level-10{

  margin-left: 180px;
}

.editable-quote-input {
  flex-grow: 1;
  border: none;
  outline: none;
}

.editable-quote-bubble{
  display: flex;
  justify-content: center;
  align-items: center;
}


.error-message-overlay {
  position:sticky;
  top: 90%;
  width: 100%; /* Take the full width of the edit-modal */
  background-color: rgba(200, 0, 0, 1); /* Semi-transparent red background */
  padding: 10px 0; /* Add some padding */
  border-radius: 20px;
  box-sizing: border-box;
}

.error-message-modal {
  text-align: center; /* Center the text */
  color: white;
  font-weight: bold;
}

.error-message-close {
  position: absolute;
  top: -20px;
  border: none;
  border-radius: 100%;
  background-color: white;
  right: -5px;
  cursor: pointer;
}

@keyframes slideUpFromBottom {
  from {
      transform: translateY(100%); /* Start from below the view */
      opacity: 0;
  }
  to {
      transform: translateY(0); /* Move to original position */
      opacity: 1;
  }
}

@keyframes slideDownToBottom {
  from {
      transform: translateY(0); /* Start from original position */
      opacity: 1;
  }
  to {
      transform: translateY(100%); /* Move down below the view */
      opacity: 0;
  }
}

.add-delete-buttons-container {
  display: flex; /* Use flexbox to layout children */
  align-items: center; /* Align items vertically in the center */
  justify-content: center; /* Center the buttons horizontally */
  gap: 10px; /* Space between the buttons */
  padding-bottom: 8px;
  padding-left: 6px;
}


.add-node-button, .delete-node-button {
  background: none; /* Remove any background */
  border: none; /* Remove the border */
  padding: 0; /* Remove padding */
  cursor: pointer; /* Change cursor to pointer to indicate clickable */
  display: flex; /* Use flex to center the image if needed */
  justify-content: center; /* Center content horizontally */
  align-items: center; /* Center content vertically */
}

.add-node-button img, .delete-node-button img {
  width: 16px; /* Adjust based on your actual image size */
  height: auto; /* Maintain aspect ratio */
}

/* If you have specific styling for hover states */
.add-node-button:hover, .delete-node-button:hover {
  scale:1.1;
}

.saved-message-overlay {
  position: sticky;
  top: 90%;
  width: 100%; /* Take the full width of the edit-modal */
  display: flex;
  align-items: center;
  justify-content: center;
  pointer-events: none; /* Allows clicks to pass through */
  z-index: 1050; /* Adjust based on your z-index needs */
}
.saved-message {
  background-color: #4CAF50; /* Green background */
  color: white; /* White text */
  padding: 15px 30px;
  border-radius: 5px;
  box-shadow: 0 4px 6px rgba(0,0,0,0.1);
  font-size: 18px;
  font-weight: bold;
  pointer-events: auto; /* Ensures this element can be interacted with if needed */
}


/* Responsive adjustments */
@media (max-width: 768px) {
  .edit-modal-overlay {
      width: 50%; /* Larger sidebar for smaller screens */
  }
}

@media (max-width: 480px) {
  .edit-modal-overlay {
      width: 75%; /* Even larger sidebar for mobile screens */
  }

  .editing-modal {
      padding: 15px;
  }

  .editing-modal textarea {
      height: 50vh; /* Adjust for mobile */
  }

  .editing-modal button {
      padding: 8px 16px;
      font-size: 0.9rem;
  }
}
.editable-quote-bubble.error {
  border: 2px solid red; /* Adjust as needed */
  animation: shake 0.5s; /* Simple shake animation */
}

@keyframes shake {
  0% { transform: translateX(0px); }
  25% { transform: translateX(-5px); }
  50% { transform: translateX(5px); }
  75% { transform: translateX(-5px); }
  100% { transform: translateX(0px); }
}
/* Style for blurring the modal content */
.blur-effect {
  filter: blur(4px);
}

/* Overlay and button styles for Freemium upgrade prompt */
.upgrade-overlay {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  background: rgba(255, 255, 255, 0.85); /* Semi-transparent white overlay */
}

.upgrade-button {
  padding: 10px 20px;
  font-size: 16px;
  cursor: pointer;
  z-index: 3000;
}

.pricing-modal{
  z-index: 3001;
}


.edit-modal-overlay.blur > .editing-modal {
  filter: blur(2px);
}

.freemium-overlay {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  height: 100vh;
  padding: 20px;
  padding-top:0px;
  padding-bottom: 20px;
  background-color: rgba(0, 0, 0, 0.6); /* Semi-transparent overlay */
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: white;
  z-index: 10080; /* Ensure it's above the modal content */
}

.freemium-overlay p {
  margin-bottom: 20px;
  font-size: 20px;
}

/* Additional styles for the freemium overlay button */
.freemium-overlay button {
  background-color: #ff69b4;
  color: white;
  padding: 0.5em 1em;
  border: none;
  border-radius: 20px;
  cursor: pointer;
}

.freemium-overlay button:hover {
  background-color: #ff4d9e;
}

.freemium-overlay button:focus {
  outline: none;
  box-shadow: 0 0 0 2px #ff69b4;
}

.freemium-overlay button:active {
  transform: scale(0.95);
}
