.onboarding-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    min-height: 100vh;
    background-color: #f7f7f7; /* Background color for the entire page */
    font-family: 'Lato', sans-serif;
  }
  
.onboarding-header {
    text-align: center;
    margin-bottom: 30px;
}
  
  .onboarding-logo {
    width: 150px; /* Adjust the size as needed */
    padding-bottom: 10px;
  }
  
  .onboarding-form {
    background-color: white; /* Different background for the form */
    padding: 40px;
    border-radius: 8px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    width: 100%;
    max-width: 600px;
  }
  
  .onboarding-form label {
    display: block;
    margin-top: 20px;
    margin-bottom: 10px;
    font-size: 16px;
    color: #333;
  }
  
  .onboarding-form select {
    width: calc(100% - 20px); /* Adjust width taking padding into account */
    padding: 10px;
    margin-bottom: 20px;
    border: 1px solid #ccc;
    border-radius: 4px;
    font-size: 16px;
    color: #333;
  }
  
  .onboarding-form select:focus {
    border-color: #007bff;
  }
  
  .continue-button {
    padding: 15px 30px;
    background-color: #e6bad1; /* Example button color */
    color: black;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    font-size: 18px;
    width: 100%;
    transition: background-color 0.3s ease;
  }
  
  .continue-button:hover {
    background-color: #e6bad1;
  }
  
  .continue-button:disabled {
    background-color: #cccccc;
  }

  .email-signup-checkbox{
    margin-right: 5px;
  }

  label[for="email-signup"] {
    position: relative;
    display: block;
  }
  
  label[for="email-signup"]::after {
    font-size: 14px;
    content: "Send me tips & tutorials to become a better learner and some product updates";
    margin-left: 5px; /* Adjust space between the checkbox and the text */
    font-style: italic; /* Example style */
    color: #333; /* Example text color */
  }
  
  .email-signup-checkbox + * {
    display: none; /* This hides the direct text node beside the checkbox */
  }