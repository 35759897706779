.flowchart-edit-modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(255, 255, 255, 0.9);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
    padding: 20px;
}

.flowchart-editing-modal {
    background-color: white;
    border-radius: 8px;
    padding: 24px;
    width: 60%;
    max-width: 600px;
    max-height: 90vh;
    overflow-y: auto;
    box-shadow: 0 4px 20px rgba(0, 0, 0, 0.15);
}

.flowchart-modal-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 16px;
    padding: 0 0 12px 0;
    border-bottom: 1px solid #e5e7eb;
}

.flowchart-modal-header h3 {
    color: #1f2937;
    font-size: 1.5rem;
    font-weight: 600;
    margin: 0;
    letter-spacing: -0.02em;
}

.modal-controls {
    display: flex;
    align-items: center;
    gap: 12px;
}

.close-button-flowchart {
    position: relative;
    background: none;
    border: none;
    color: #94a3b8;
    cursor: pointer;
    top:-16px;
    left:16px;
    padding: 8px;
    border-radius: 6px;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: all 0.2s ease;
}

.close-button-flowchart:hover {
    color: #64748b;
    background-color: #f1f5f9;
}

.close-button-flowchart svg {
    width: 20px;
    height: 20px;
}

.flowchart-nodes-section {
    display: flex;
    flex-direction: column;
    gap: 16px;
    padding: 16px;
    background-color: #fafafa;
    border-radius: 8px;
    margin-bottom: 20px;
}

.node-edit-container {
    margin-bottom: 12px;
    padding: 12px;
    border-radius: 8px;
    background-color: white;
    border: 1px solid #e5e7eb;
    transition: border-color 0.2s ease;
}

.node-edit-container:hover {
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.node-id {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 12px;
    font-family: 'SF Mono', 'Consolas', monospace;
    color: #334155;
}

.node-type {
    color: #64748b;
    font-size: 0.9em;
    font-weight: 500;
    background-color: #f1f5f9;
    padding: 2px 6px;
    border-radius: 4px;
}

.node-actions {
    display: flex;
    gap: 8px;
}

.add-child-button,
.delete-node-button {
    display: flex;
    align-items: center;
    gap: 6px;
    padding: 6px 12px;
    border: none;
    border-radius: 6px;
    cursor: pointer;
    font-size: 13px;
    font-weight: 500;
    transition: background-color 0.2s ease;
    color: white;
}

.add-child-button {
    background-color: #10b981;
}

.add-child-button:hover {
    background-color: #059669;
}

.delete-node-button {
    background-color: #f43f5e;
}

.delete-node-button:hover {
    background-color: #e11d48;
}

.add-child-button:disabled,
.delete-node-button:disabled {
    opacity: 0.5;
    cursor: not-allowed;
}

.textarea-container {
    position: relative;
    display: flex;
    align-items: flex-start;
    gap: 8px;
}

.node-text-input {
    width: 100%;
    min-height: 20px;
    padding: 8px 12px;
    border-radius: 6px;
    border: 2px solid #e2e8f0;
    font-family: system-ui, -apple-system, sans-serif;
    font-size: 14px;
    resize: none;
    overflow: hidden;
    transition: border-color 0.2s ease;
    box-sizing: border-box;
}

.node-text-input:focus {
    outline: none;
    border-color: #60a5fa;
}

.icon-section {
    position: relative;
}

.icon-button {
    background: none;
    border: 1px solid #ddd;
    border-radius: 4px;
    cursor: pointer;
    padding: 8px;
    color: #666;
    display: flex;
    align-items: center;
    justify-content: center;
    min-width: 36px;
    min-height: 36px;
}

.icon-button:hover {
    color: #333;
    background-color: #f5f5f5;
}

.icon-button:disabled {
    opacity: 0.5;
    cursor: not-allowed;
}

.icon-dropdown-container {
    position: absolute;
    top: 100%;
    left: 0;
    z-index: 1000;
    margin-top: 4px;
}

.autosave-status {
    font-size: 0.875rem;
    color: #64748b;
    font-weight: 500;
    padding: 6px 12px;
    background-color: #f8fafc;
    border-radius: 6px;
    border: 1px solid #e2e8f0;
    display: flex;
    align-items: center;
    gap: 6px;
}

.autosave-status.saving {
    color: #eab308;
}

.autosave-status.error {
    color: #dc2626;
}

.diagram-container {
    margin-top: 20px;
    padding: 16px;
    border: 1px solid #eee;
    border-radius: 6px;
}