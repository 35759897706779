.mermaid {
  display: flex;
  justify-content: center; /* Center horizontally */
  height: 100vh; /* Full height of the viewport */
  z-index: 0;
  padding-top: 100px;
}

/* Override Mermaid styles specifically for Gantt charts */
.mermaid.gantt {
  padding-top: 100px;
  display: block; /* Change flex to block for Gantt */
  justify-content: initial; /* Remove justify-content for Gantt */
  height: 100vh; /* Let the Gantt chart define its own height */
}


.chart-container{
  display: block;
}


.pdf-to-mindmap-container {
  position: relative; /* Needed for absolute positioning of child elements */
}

.node-summary-modal{
  position: relative;
  z-index: 1000;
}

.file-name-app{
  display: flex;
  flex-direction: row;
  margin-left: 10px;
  margin-top: auto;
  margin-bottom: auto;
  font-size: 20px;
  font-weight: 300;
}

.division{
  font-weight: 200;
}

.centered-content{
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh; /* Adjust height as needed */
}

.loader {
  position: absolute; /* Make the position absolute to ensure it's placed relative to the viewport */
  top: 50%; /* Center vertically */
  left: 50%; /* Center horizontally */
  transform: translate(-50%, -50%); /* Adjust the positioning to truly center the loader */
  
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.mindmap-header-right{
  display: flex;
  flex-direction: row;
  justify-content:flex-end;
  align-items: center;
  gap:10px;
}

.mindmap-header-left{
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  gap:10px;
}

.edit-mindmap{
  border: none;
  color: white;
  transition: left 0.5s ease; 
}

/* Responsive adjustments */
@media only screen and (max-width: 768px) {
  .loader {
    margin-top: 0;
    margin-bottom: 0;
    margin-left: 0;
  }
  .mindmap-header{
    margin-left: 0px;
  }
}


.save-buttons {
  border: none;
  color: white;
  transition: left 0.5s ease; 
}

.contact-us-button {
  position: fixed;
  bottom: 20px;
  right: 20px;
  z-index: 1000; /* Ensure it stays on top */
  /* Add more styling as needed */
}

.overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5); /* Semi-transparent background */
  z-index: 1001; /* Ensure it's above everything else */
  display: flex;
  justify-content: center;
  align-items: center;
}
.error-message {
  color: red;
  font-weight: bold;
  position: absolute;
  top: 300px; /* Adjust the value to position it above the upload icon */
  left: 50%;
  transform: translateX(-50%);
  z-index: 11;
}

.page-layout {
  display: flex;
  flex-direction: column;
  height: 100vh; /* Assuming you want your app to fill the whole viewport height */
}

.main-content {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  overflow: auto;
  height: 100vh; 
}

.app-header-image{
  cursor: pointer;
  display: block;
  width: 140px;
  padding:10px;
  height: auto;
}

.mindmap-centering-wrapper {
  display: flex;
  margin: auto;
  width: 100%;
  height: 100%;
  overflow: auto;
}


.SideNavBar {
  flex: 0 0 250px; 
  height: 100vh; 
  position: fixed; 
  left: 0; 
  top: 0;
}

/* Assuming TileGallery, FileUpload, and MindMap are direct children of .page-layout and siblings to each other */
.TileGallery, .FileUpload, .MindMap {
  flex-grow: 1; /* Allows the component to take up the remaining space */
  overflow-y: auto; /* Add scroll to the content area if content overflows */
  height: 100%; /* Full height */
  /* Other styles */
}

.mindmap-container {
  /* Styles specific to mindmap-container if needed */
  height: 80%; /* Full height */
}

.new-icon, .edit-icon, .export-icon, .share-icon{
  width:30px;
  height: auto;
  border:none;
  background-color: transparent;
}

.export-icon{
  width: 40px;
  height: auto;
  border: none;
  background-color: transparent;

}

.edit-icon{
  width: 34px;
  height: auto;
  border: none;
  background-color: transparent;

}

.translate-button:focus{
  outline: none; /* Removes the blue outline */
}

.share-icon{
  width: 36px;
  margin-right: 10px;
}

.translate-icon{
  width: 34px;
  height: auto;
  border: none;
  background-color: transparent;
}

.share-button:focus{
  outline: none; /* Removes the blue outline */
}

.disclaimer{
  text-align: center;
  margin-bottom: -10px;
  color: #6c757d;
  font-size: 12px;
}

.dropdown {
  position: absolute;
  display: inline-block;
}

.save-buttons {
  border: none;
  background-color: transparent;
  cursor: pointer;
  padding: 8px;
  border-radius: 4px;
}

/* General improvements for dropdown container */
.dropdown-content {
  display: none;
  position: absolute;
  right: 100px;
  background-color: #f9f9f9;
  min-width: 160px; /* Increased width for better text visibility */
  box-shadow: 0 8px 16px rgba(0,0,0,0.2);
  border-radius: 4px; /* Added rounded corners */
  overflow: hidden; /* Ensures the border-radius applies to child elements */
  z-index: 1;
}

/* Enhanced dropdown button styling for better usability */
.dropdown-content button {
  color: #333; /* Slightly darker for better contrast */
  padding: 12px 16px;
  text-decoration: none;
  display: block;
  width: 100%;
  background: none;
  border: none;
  text-align: left;
  box-sizing: border-box;
  transition: background-color 0.2s; /* Smooth background transition */
}

/* Improved hover state for better feedback */
.dropdown-content button:hover {
  background-color: #f1f1f1;
}

/* Show the dropdown menu on hover with improved accessibility */
.export-dropdown:hover .dropdown-content,
.export-dropdown:focus-within .dropdown-content {
  display: block;
  opacity: 0;
  transition: opacity 0.3s ease-in-out;
  visibility: hidden; /* Use visibility to ensure it's not interactable when hidden */
}

.export-dropdown:hover .dropdown-content,
.export-dropdown:focus-within .dropdown-content {
  opacity: 1;
  visibility: visible; /* Make it visible and interactable when shown */
}

/* Optional: Enhanced keyboard navigation support */
.dropdown-content button:focus {
  outline: none;
  background-color: #ddd; /* Different background for keyboard focus */
}

/* Animation refinement */
.export-dropdown-content {
  transition: opacity 0.3s ease-in-out, visibility 0s linear 0.3s; /* Adjusted for combined opacity and visibility transitions */
}

.dropdown:hover .dropdown-content,
.dropdown:focus-within .dropdown-content {
  transition-delay: 0s; /* Ensures visibility turns to visible immediately on hover/focus */
}

.new-mindmap:focus, .save-buttons:focus, .edit-mindmap:focus, .share-button:focus, .translate-button:focus {
  outline: none; /* Removes the blue outline */
}


.new-mindmap, .save-buttons, .edit-mindmap, .share-button, .translate-button{
  border:none;
  position: relative; /* Needed to position the tooltip */
  display: inline-block; /* Adjust if needed */
}

.new-mindmap .tooltip-text, .save-buttons .tooltip-text, .edit-mindmap .tooltip-text, .share-button .tooltip-text, .translate-button .tooltip-text{
  visibility: hidden;
  width: 120px;
  background-color: black;
  color: white;
  text-align: center;
  border-radius: 6px;
  padding: 5px 0;
  font-weight: 300;
  position: absolute;
  z-index: 1;
  bottom: -50%; /* Position above the button */
  left: 50%;
  margin-left: -60px; /* Center the tooltip */
  opacity: 0;
  transition: opacity 0.3s;
}



.new-mindmap .tooltip-text::after, .save-buttons .tooltip-text::after, .edit-mindmap .tooltip-text::after, .share-button .tooltip-text::after, .translate-button .tooltip-text::after{
  content: "";
  position: absolute;
  bottom: 100%;
  left: 90%;
  margin-left: -5px;
  border-width: 5px;
  border-style: solid;
  border-color: transparent transparent black transparent; /* Triangle pointing up */
}

.new-mindmap:hover .tooltip-text, .save-buttons:hover .tooltip-text, .edit-mindmap:hover .tooltip-text, .share-button:hover .tooltip-text, .translate-button:hover .tooltip-text{
  visibility: visible;
  opacity: 1;
}

.mindmap-header{
  box-sizing: border-box;
  width: 100%;
  position: relative;
  background-color: #ededed;
  box-shadow: -2px 10px 0px rgba(224, 218, 218, 0.8);
  border-radius: 20px;
  color: black;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items:center;
  text-align: left;
  margin: auto;
  margin-top: 10px;
  padding-left: 10px;
  padding-right:10px; 
  z-index: 10;
}


/* Media query for screens smaller than 768px */
@media (max-width: 768px) {
  .mindmap-header {
    margin-left: 0px;/* Remove the left margin on mobile screens */
    width: 100%; /* Optional: You might want to stretch the header to full width on smaller screens */
    margin-top:25px;
  }
  .file-name-app {
    display: none;
}
}

@media (max-width: 1300px) {
 .file-name-app{
  display: none;
  }
}

.error-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.5); /* Semi-transparent dark background */
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1000; /* Ensures it overlays other content */
}

.error-modal {
  background-color: #ffffff;
  padding: 30px 40px;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  text-align: center;
  max-width: 90%;
  width: 400px; /* Sets a consistent width for the modal */
}

.error-modal p {
  font-size: 1.1em;
  color: #333;
  margin-bottom: 20px;
}

.retry-button {
  background-color: #1a1a1a;
  color: #ffffff;
  padding: 10px 20px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-weight: bold;
  transition: background-color 0.3s;
}

.retry-button:hover {
  background-color: #101010; /* Darker shade on hover */
}