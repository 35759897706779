.cta-container {
    display: flex;
    flex-direction: row; /* Change to a vertical layout on mobile */
    align-items: center;
    justify-content: center;
    padding: 40px 20px;
}

.cta-image {
    max-width: 20%; /* Allow the image to take full width on mobile */
    height: auto;
    margin-bottom: 20px; /* Add some space below the image */
}

.cta-text {
    max-width: 400px;
}

.cta-heading {
    color: #333;
    font-size: 1.5rem; /* Decrease the font size for smaller screens */
    margin-bottom: 10px; /* Reduce the margin bottom */
    max-width: 100%; /* Allow the heading to take full width on mobile */
}

.cta-subheading {
    color: #666;
    font-size: 0.8rem; /* Decrease the font size for smaller screens */
    margin-bottom: 20px; /* Reduce the margin bottom */
}

.cta-button {
    padding: 10px 20px;
    background-color: #0056b3;
    color: white;
    border: none;
    border-radius: 5px;
    font-size: 0.8rem; /* Decrease the font size for smaller screens */
    cursor: pointer;
    transition: background-color 0.3s;
    margin-bottom: 40px;
}

.cta-button:hover {
    background-color: #003d82;
}

/* Media query for screens with a maximum width of 768px (typical mobile devices) */
@media (max-width: 768px) {
    .cta-container {
        flex-direction: column;
        padding: 20px; /* Reduce the padding on mobile */
    }
    .cta-image {
        display: none;
    }

    .cta-heading {
        margin-top: 40px;
        font-size: 1.2rem; /* Increase the font size for smaller screens */
        max-width: 100%; /* Allow the heading to take full width on mobile */
    }

    .cta-subheading {
        font-size: 0.9rem; /* Increase the font size for smaller screens */
    }

    .cta-button {
        font-size: 0.9rem; /* Increase the font size for smaller screens */
        margin-bottom: 100px;
    }

    .cta-text {
        align-items: center;
        text-align: center;
    }
}
