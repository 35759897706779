.modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 2000;
  }
  
  .delete-modal {
    background: white;
    width: 40%;
    height: auto; 
    max-width: auto; /* New max-width for larger screens */
    min-width: auto;
    min-height: auto;
    border-radius: 10px;
    overflow: hidden;
    box-shadow: 0 4px 16px rgba(0, 0, 0, 0.2);
    display: flex; /* Ensure flex layout */
    flex-direction: column; /* Stack children vertically */
    position: relative; /* Added to serve as a positioning context for the absolute button */
  }
  
  .modal-close {
    position: absolute;
    right: 10px;
    top: 10px;
    border: none;
    background: none;
    cursor: pointer;
    font-size: 1.5rem;
    z-index: 1000;
  }
  
  .warning-icon {
    color: #FFA500; /* Orange color */
    font-size: 2.5rem; /* Adjust as needed */
    margin-top: 1rem;
  }
  
  .delete-modal-content {
    padding: 2rem;
  }
  
  .modal-title {
    font-size: 1.5rem;
    font-weight: bold;
    margin-top: 1rem;
  }
  
  .modal-body {
    margin-top: 1rem;
    margin-bottom: 2rem;
    font-size: 1rem;
  }
  
  .modal-cancel, .modal-delete {
    padding: 10px 20px;
    border: none;
    border-radius: 5px;
    font-size: 1rem;
    cursor: pointer;
    transition: background-color 0.3s;
  }
  .modal-action-buttons {
    display: flex;
    gap: 1rem;
    justify-content: center;
    margin-bottom: 1rem;
  }
  
  .modal-cancel {
    background-color: #f0f0f0;
  }
  
  .modal-cancel:hover {
    background-color: #e0e0e0;
  }
  
  .modal-delete {
    background-color: #ff4d4d; /* Red color */
    color: white;
  }
  
  .modal-delete:hover {
    background-color: #ff3333;
  }

  @media (max-width: 768px) {
    .delete-modal {
      width: 90%; /* Increase width for smaller screens to use more space */
      min-width: 250px; /* Adjust minimum width for very small screens */
    }
  }
  
  @media (max-width: 480px) {
    .modal {
      width: 95%; /* Use almost full width for very small screens */
      min-width: 200px; /* Adjust minimum width for mobile screens */
    }
    
    .modal-close {
      font-size: 1.2rem; /* Adjust close button size for mobile screens */
    }
  
    .warning-icon {
      font-size: 2rem; /* Adjust icon size for mobile screens */
    }
  
    .modal-title, .modal-body {
      font-size: 1rem; /* Adjust font size for mobile screens */
    }
  
    .modal-cancel, .modal-delete {
      padding: 8px 16px; /* Adjust button padding for mobile screens */
      font-size: 0.9rem; /* Adjust button font size for mobile screens */
    }
  }
  