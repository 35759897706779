/* Layout and Sidebar */
.account-layout {
  display: flex;
  min-height: 100vh;
  overflow: scroll;
}

.pricing-section-my-account {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 50px;
}

  /* Mobile Styling */
  @media (max-width: 768px) {
    .pricing-section-my-account {
      flex-direction: column;
      gap: 20px;
      overflow-y: scroll;
      overflow: scroll;
    }
  }
  
.trial-information{
  padding: 40px;
  border: solid;
  border-color: grey;
  border-radius: 20px;
  margin-bottom: 40px;
  max-width: 600px;
}

  .side-navbar {
    width: 250px;
    position: fixed;
    left: 0;
    top: 0;
    bottom: 0;
    background-color: #f0f0f0; /* Adjust based on your theme */
    overflow-y: auto;
  }

  .plan-details, .personal-details, .delete-content{
    padding: 40px;
    border: solid;
    border-color: grey;
    border-radius: 20px;
    margin-bottom: 40px;
  }
  .delete-content{
    max-width: 400px;
  }

  .pricing-tier{
    padding: 40px;
    border-radius: 12px;
    background-color: rgba(128, 128, 128, 0.2);
    max-width: 350px;
    min-height: 400px;
  }

  .pricing-tier.pro-plan{
    padding: 40px;
    border-radius: 12px;
    background-color: white;
    border: #6c757d solid 2px;
    max-width: 350px;
    min-height: 400px;
  }

  .pricing-tier:hover {
    box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.2);
    scale: 1.05;
    transition: transform 5s ease-in-out;
  }

  .plan-name-card{
    display:flex;
    flex-direction: row;
    align-items: flex-end;
    justify-content: space-between;
    padding-bottom: 10px;
  }

  .pricing-tier p{
    font-weight: 300;
  }

  .pricing-tier ul{
    font-weight: 600;
  }



  .personal-details h1{
    padding-bottom: 20px;
  }

  .delete-content h1{
    padding-bottom: 20px;
  }

  .subscription-information h1{
    padding-bottom: 20px;
  }

  .upgrade-button, .manage-sub-button{
    background-color: #ff69b4;
    color: white;
    padding: 0.5em 1em;
    border: none;
    border-radius: 20px;
    margin-top: 2em;
    cursor: pointer;
  }

  .subscription-information.with-upgrade{
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    max-width: 350px;
    gap: 10px;
  }
  
  /* Options Menu */
  .my-account-options-menu{
    color: black;
    display: flex;
    justify-content:center;
    padding-top: 20px;
    position: fixed;
    width: 100%;
    z-index: 100;
    gap: 10px;
    box-shadow: none;
  }
  
  .menu-option {
    padding: 10px 20px;
    border-radius: 10px;
    border: none;
    background-color: #e7e7e7; /* Light grey, adjust as needed */
    cursor: pointer;
    transition: background-color 0.2s;
  }
  
  .menu-option.active {
    background-color: #f370ffff; /* Bootstrap primary color for example */
    color: white;
  }
  
  /* Section Content */
  .section-content {
    display: flex;
    justify-content:center;
    padding-top: 150px;
    width: 100%;
    z-index: 10;
    gap: 10px;
    box-shadow: none;
  }

  .inner-content{
    display: flex;
    flex-direction: column;
  }
  
  /* Danger Zone specific styling */
  .danger-button {
    background-color: #dc3545; /* Bootstrap danger color */
    color: white;
    padding: 10px 15px;
    border: none;
    border-radius: 20px;
    cursor: pointer;
  }

  .account-delete-modal {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: white;
    padding: 20px;
    z-index: 1000;
    width: 100%;
    max-width: 400px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    border-radius: 8px;
  }

  .button-container{
    display: flex;
    flex-direction:column;
    gap: 10px;
  }
  
  .account-delete-modal p {
    font-weight: 200;
    margin-bottom: 20px;
  }
  
  .account-delete-modal input {
    width: 100%;
    padding: 10px;
    margin-bottom: 20px;
    border: 1px solid #ccc;
    border-radius: 4px;
    min-height: 40px;
  }
  
  .account-delete-modal .warning-icon{
    display: flex;
    margin: auto;
  }
  .account-delete-modal button {
    padding: 10px 15px;
    border: none;
    border-radius: 24px;
    cursor: pointer;
    margin-right: 10px;
  }
  
  .account-delete-modal button:disabled {
    background-color: #ccc;
    cursor: not-allowed;
  }
  
  .account-delete-modal button:not(:disabled) {
    background-color: #dc3545;
    color: white;
  }
  
  .account-delete-modal button:nth-child(2) {
    background-color: #dc3545;
  }
  
  .account-delete-modal button:nth-last-child(1) {
    background-color: #6c757d;
    color: white;
  }

  .account-delete-modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    background-color: rgba(0, 0, 0, 0.5);
    z-index: 999; /* Ensure it's below the modal but above other content */
  }
  
  
  
  /* Mobile responsiveness */
  @media (max-width: 768px) {
    .side-navbar {
      width: 100%;
      position: relative; /* Adjust based on mobile design */
    }
  
    .section-content, .my-account-options-menu {
      padding: 10px;
    }
    .section-content{
      padding-top: 150px;
    }
    .my-account-options-menu{
      margin-top: 40px;
    }

  }

  /* Existing Mobile Styling Adjustment */
@media (max-width: 768px) {
  .pricing-section-my-account {
    flex-direction: column;
    gap: 20px;
    overflow-y: scroll; /* Ensure it's scrollable if content overflows */
  }

  .pricing-tier, .subscription-information {
    max-width: 100%; /* Ensure it doesn't overflow the screen */
    padding: 20px; /* Slightly reduce padding */
  }

  .pricing-tier h2, .pricing-tier h6, .pricing-tier p, .pricing-tier ul {
    font-size: smaller; /* Adjust text size for mobile readability */
  }

  .upgrade-button, .manage-sub-button {
    padding: 12px 24px; /* Slightly larger touch targets */
    font-size: larger; /* Ensure text is easily readable */
  }
}

  