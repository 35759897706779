.options-menu-overlay {
    position: fixed;
    top: -20px;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.4);
    display: flex;
    align-items: flex-start;
    justify-content: center;
    z-index: 1000;
  }
  
  .options-menu {
    background: white;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    z-index: 1001;
  }
  
  .options-menu-list {
    list-style: none;
    margin: 0;
    padding: 0;
  }
  
  .options-menu-item {
    padding: 10px 20px;
    border-bottom: 1px solid #eee;
    cursor: pointer;
    display: flex; /* Align icon and text */
    align-items: center; 
  }
  
  .options-menu-item:last-child {
    border-bottom: none;
  }
  
  .options-menu-item:hover {
    background-color: #f6f6f6;
  }
  .options-icon {
    margin-right: 8px; /* Space between icon and text */
  }
  