.tool-cards-container {
    display: flex;
    flex-direction: column;
    align-items: center; /* Centers the whole section */
    margin: 40px;
    padding: 20px;  
    padding-bottom: 100px;  
  }

  
  .tool-cards {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(200px, 1fr)); 
    gap: 20px;
    justify-content: center; /* Centers the grid */
    width: 100%;
    max-width: 1000px; /* Limits the width to center the grid */
    margin: 0 auto; /* Ensures the grid itself is centered within its container */
}

  
  .tool-card {
    display: flex;
    flex-direction: row; /* Align image and text horizontally */
    align-items: center; /* Vertically centers the content inside the card */
    padding: 20px;
    border: 1px solid #e0e0e0;
    border-radius: 10px;
    background-color: #ffffffe3;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    text-align: left; /* Aligns text to the left */
    transition: transform 0.3s ease;
    justify-content: flex-start; /* Align content to the left */
  }
  
  .tool-card:hover {
    transform: translateY(-5px); /* Adds a slight lift on hover */
  }
  
  .tool-icon {
    width: 60px; /* Set icon size */
    height: 60px; /* Maintain a consistent size */
    margin-right: 15px; /* Add space between the icon and text */
    margin-bottom: 10px;
  }
  
  .tool-cards-heading {
    font-size: 48px;
    margin-bottom: 20px;
    font-weight: bold;
    padding-bottom: 100px;
  }
  
  .tool-card-title {
    font-size: 20px;
    font-weight: 400;
    color: #333;
    margin: 0; /* Remove extra margin around the text */
  }
  