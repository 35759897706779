.mind-map-image{
    width: 1.5em;
}

.works-in-simple{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    gap: 20px;
}

  /* Adjust the image size */
  .mind-map-image {
    width: 1.5em;
  }
  
  /* Responsive adjustments */
  @media (max-width: 768px) {
    .mind-map-image {
      width: 1em;
    }
    
    .works-in-simple {
      gap: 5px;
    }
  
  }
  
.trusted-by {
    display: flex;
    flex-direction: row;
    align-items: center;
    text-align: left; /* Aligns the 'Trusted By' heading to the left */
    gap: 40px;
  }

.highlight-blue{
    background: linear-gradient(to bottom, rgba(11, 121, 175, 0.31), rgba(11, 121, 175, 0));
    padding-left: '5px';
    border-left: 3px solid #184a63;
    padding: 2%;  
}
  
  .scroller {
    max-width: 1000px;
    margin-left: auto; /* This pushes the scroller to the right of 'Trusted By' */
    overflow: hidden;
    align-self: stretch;
  }
  
  .scroller__inner {
    display: flex;
    flex-wrap: nowrap;
    gap: 1rem;
    animation: scroll var(--_animation-duration, 40s) linear infinite;
  }
  
  @keyframes scroll {
    100% {
      transform: translateX(calc(-100% - 1rem));
    }
  }
  
  /* Define speeds */
  .scroller[data-speed="fast"] {
    --_animation-duration: 20s;
  }
  
  .scroller[data-speed="slow"] {
    --_animation-duration: 60s;
  }
  .div{
    margin-bottom: 2em;
  }
  
  /* Styling for individual items */
  .tag-list ul {
    font-size: 30px;
    font-weight: 400;
  }
  
  /* Mask for fading at the edges */
  .scroller[data-animated="true"] {
    -webkit-mask: linear-gradient(
      to right,
      transparent,
      black 20%,
      black 80%,
      transparent
    );
    mask: linear-gradient(
      to right,
      transparent,
      black 20%,
      black 80%,
      transparent
    );
  }
  
  /* Hide scrollbar */
  .scroller::-webkit-scrollbar {
    display: none;
  }
  
  .scroller {
    -ms-overflow-style: none;  /* IE and Edge */
    scrollbar-width: none;  /* Firefox */
  }
  

  @media (max-width: 768px) {
    .mind-map-image{
        width: 1em;
    }

    .works-in-simple{
        flex-direction: row;
        gap: 5px;
    }
    .trusted-by{
        display: flex;
        flex-direction: column;
        align-items: center;
    }

    .scroller{
        max-width: 768px;
    }
}
  
  
