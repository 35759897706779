/* SaleBanner.css */
.saleBanner {
    color: black;
    text-align: center;
    font-size: 46px;
    font-weight: 500;
    background: url('../../../public/background/BackgroundHero.png') no-repeat center center;
    background-size:cover;
    padding: 20px;
  }
  .saleMessage{
    font-size: 46px;
  }
  
  .countdown {
    margin-top: 10px; /* Space between messages */
    font-size: 26px;
  }
  
  .countdownMessage {
    font-size: 32px;
  }
  

  .timeBox {
    background-color: rgb(17, 17, 17);
    color: pink; /* This should match the background color for contrast or your preferred color scheme */
    border-radius: 10px; /* Adjust the radius to your liking */
    padding: 5px 10px; /* Adjust padding to fit the content nicely */
    margin: 0 5px; /* Space between each box */
    display: inline-block; /* Ensures the boxes align properly inline */
  }

  .discountMessage{
    margin-top: 40px;
    font-size: 24px;
    font-weight: 300;
    color:grey;
  }

  .discountButton{
    margin-top: 20px;
  }
  