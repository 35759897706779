.mermaid{
  padding-top:0px;
}
.main-layout-mindmap-viewer{
    overflow-y: hidden;
    overflow-x: hidden;
}

.hamsterLoader{
    display: flex;
    justify-content: center; /* Center horizontally */
    align-items: center; /* Center vertically */
    height: 80vh; /* Use the full viewport height */
}

.header-and-mindmap-container{
    min-height: 100vh;
}

.mindmap-header{
    max-width: 90vw;
    position: fixed;
    left: 50%; /* Set the left edge of the element to the middle of the viewport */
    transform: translateX(-50%); /* Shift the element left by half its own width */
    width: 90vw; /* Adjust the width as necessary, or use a specific width */
    margin-top: 20px;

}

.make-own-mindmap {
    padding: 6px 12px;
    font-size: 18px;
    font-weight: 600;
    color: #4a154b; /* A deep purple for contrast */
    background-color: #e6bad1; /* Soft pink base color */
    border: 2px solid #d18ba7; /* Slightly darker pink for the border */
    border-radius: 20px;
    cursor: pointer;
    transition: background-color 0.3s, transform 0.2s, box-shadow 0.2s;
    box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16);
    outline: none;
    user-select: none; /* Prevent text selection */
  }
  
  .make-own-mindmap:hover {
    background-color: #d18ba7; /* Darker shade for hover */
    transform: translateY(-2px);
    box-shadow: 0 5px 12px rgba(0, 0, 0, 0.2);
  }
  
  .make-own-mindmap:active {
    background-color: #c57a95; /* Even darker for active state */
    border-color: #b66982;
    transform: translateY(1px);
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
  }
  
  /* Animation for additional attention */
  @keyframes pulse {
    0% {
      transform: scale(1);
    }
    50% {
      transform: scale(1.05);
      box-shadow: 0 0 0 15px rgba(230, 186, 209, 0);
    }
    100% {
      transform: scale(1);
    }
  }
  
  .make-own-mindmap:focus:not(:active) {
    animation: pulse 2s infinite;
  }
  

  .mindmap-container{
    padding-top:60px;
  }