.hero-container{
  padding: 20px;
  overflow: hidden;
  padding-bottom:100px;
}
.highlight-pdf{
  background: linear-gradient(to bottom, rgba(11, 121, 175, 0.31), rgba(11, 121, 175, 0));
  padding-left: '5px';
  padding: 2%;  
}

.highlight-mindmap {
  background: linear-gradient(
    180deg,
    rgba(255, 202.94, 205.96, 0.31) 0%,
    rgba(244, 175, 179, 0) 99.52%
  );
  border-left: 3px solid #f4afb3;
  padding: 2%;
}

.hero-text h1{
  font-size:60px;
  line-height: 1.4;
}

.hero-note{
  margin-top: 10px;
  font-size: 10px;
  font-weight: 200;
}

.hero-description{
  font-size: 20px;
  font-weight: 200;
  padding-top: 30px;
  padding-bottom: 30px;
  padding-left: 200px;
  padding-right: 200px;

}

.hero-container{
  display: flex;  
  flex-direction: row;
  text-align: center;
  padding-top: 40px; /* orginally 140px*/
}


.hero-text{
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 0 20px;
  width: 50%;
}

.video-container{
  width: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.trust-building{
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 0 20px;
  width: 100%;
  gap: 80px;
  padding-top: 60px;  
}


.trust-building-container{
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 40px;
  width: 100%;
  font-size: 26px;
  align-self: stretch;
  background-color: #d9d9d9;
  border-radius: 20px;
  margin-bottom: 40px;
}

.trust-building-container.hero-review{
  display: flex;  
  flex-direction: column;
  text-align: center;
  align-items: flex-end;
  text-align: left;
}
.reviewer-designation{
  font-size: 20px;
  font-weight: 200;
  padding-top: 10px;
}

.trust-building-container.hero-review img{
width: 100px;
align-items: flex-end;
}

.trust-building-container.hero-join{
  display: flex;  
  flex-direction: column;
  text-align: center;
}
.trust-building-container.hero-join p{
  font-size: 30px;
  font-weight: bolder;
  line-height: 1;
  padding-bottom: 20px;
}

.trust-building-container.hero-join img {
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: 40px;
  border-radius: 50%;
}


.trust-building-container.hero-generated-today{
  display: flex;  
  flex-direction: column;
  text-align: center;
}

.trust-building-container.hero-generated-today span{
  font-weight: bolder;
}
.trust-building-container.hero-generated-today img{
  width: 190px;
}

.counter{
  font-size: 50px;
  font-weight: bolder;
  padding-top: 20px;
}


@keyframes blink-caret {
  from, to { border-color: transparent }
  50% { border-color: black; }
}

.highlight-pdf {
  border-right: .1em solid black;
  padding-right: 2px;
  animation: blink-caret .75s step-end infinite;
}

@media (max-width: 1280px) {
  .hero-description{
    font-size: 20px;
    font-weight: 200;
    padding-top: 50px;
    padding-bottom: 30px;
    padding-left: 40px;
    padding-right: 40px;
  
  }
}


@media (max-width: 768px) {
  .hero-container {
    width: 100%;
    align-items: center;
    flex-direction: column;
  }
  .hero-text {
    width: 100%;
    padding: 20px;
  }

  .trust-building{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 0 20px;
    width: 100%;
    gap: 40px;
    padding-top: 60px;  
  }

  .trust-building-container{
    padding: 40px;
    width: 100%;
    font-size: 20px;
    align-self: stretch;
    background-color: #d9d9d9;
    border-radius: 20px;
    margin-bottom: 20px;
  }


  .hero-description{
    font-size: 20px;
    font-weight: 200;
    padding-top: 50px;
    padding-bottom: 30px;
    padding-left: 10px;
    padding-right: 10px;
  
  }
  .video-container{
    margin-top: 40px;
    width: 100%;
  }
}
