/* FAQ container */
.faq-container {
    max-width: 1200px;
    margin: 0 auto;
    margin-top: 50px;
    border: none;
    font-family: 'Lato', sans-serif;
}

.question-faq {
    font-weight: bold;
    font-family: 'Lato', sans-serif;
}

.faq-heading {
    padding-top: 100px;
    max-width: 400px;
    font-family: 'Lato', sans-serif;
}

.accordian-faq {
    margin-top: 80px;
    margin-bottom: 100px;
    font-family: 'Lato', sans-serif;
}

/* Mobile optimization */
@media (max-width: 600px) {
    .faq-container {
        max-width: 80%;
        margin-top: 20px;
    }

    .faq-heading {
        padding-top: 50px;
        max-width: 100%;
        font-weight: 500;
        font-size: 2em;
        font-family: 'Lato', sans-serif;
    }

    .accordian-faq {
        margin-top: 40px;
        margin-bottom: 60px;
    }
}
