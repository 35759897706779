.chat-panel-wrapper {
  position: fixed;
  left: 0;
  top: 0;
  height: 100vh;
  display: flex;
  z-index: 1000;
  width: 440px;
  transform: translateX(0);
  transition: transform 0.5s cubic-bezier(0.4, 0, 0.2, 1);
  margin-right: 10px;
  text-align: left;
}

.chat-panel-wrapper.closed {
  transform: translateX(-400px);
}

.chat-container {
  width: 400px;
  height: 100vh;
  background: #ffffff;
  border-right: 1px solid rgba(236, 182, 197, 0.3);
  display: flex;
  flex-direction: column;
  position: relative;
  box-shadow: 2px 0 12px rgba(236, 182, 197, 0.2);
  transition: opacity 0.5s ease-in-out, transform 0.5s ease-in-out;
}

.chat-tab {
  position: absolute;
  left: 400px;
  top: 50%;
  transform: translateY(-50%);
  width: 40px;
  height: 80px;
  background-color: #ecb6c5;
  border: none;
  border-radius: 0 8px 8px 0;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  box-shadow: 2px 0 8px rgba(0, 0, 0, 0.1);
  z-index: 1001;
  transition: left 0.5s cubic-bezier(0.4, 0, 0.2, 1), background-color 0.3s ease;
}

.chat-tab.closed {
  left: 0px;
}

.chat-tab:hover {
  background-color: #e5a3b5;
}

.panel-header {
  padding: 20px;
  border-bottom: 1px solid rgba(236, 182, 197, 0.3);
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: #fff;
  position: relative;
}

.panel-title {
  font-size: 1.25rem;
  font-weight: 600;
  color: #333;
  margin: 0;
}

.messages-area {
  flex: 1;
  overflow-y: auto;
  padding: 20px;
  background-color: #fafafa;
}

.welcome-message {
  text-align: left;
  color: #333;
  padding: 24px;
  background-color: #fff;
  border-radius: 12px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.05);
}

.welcome-message h3 {
  margin-bottom: 16px;
  color: #000;
  font-size: 1.1rem;
}

.welcome-message p {
  color: #666;
  line-height: 1.6;
  margin: 0;
}

.message {
  margin: 12px 0;
  padding: 14px;
  border-radius: 12px;
  max-width: 80%;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.05);
}

.message.user {
  background-color: #ecb6c5;
  color: #000;
  margin-left: auto;
  text-align: left;
  font-size: 14px;
  font-weight: normal;
}

.message.bot {
  background-color: #fff;
  color: #000;
  border: 1px solid rgba(236, 182, 197, 0.3);
  text-align: left;
  font-size: 14px;
  font-weight: normal;
}

.typing-indicator {
  display: flex;
  gap: 4px;
  padding: 14px;
  background-color: #fff;
  border-radius: 12px;
  width: fit-content;
  border: 1px solid rgba(236, 182, 197, 0.3);
}

.typing-indicator span {
  width: 8px;
  height: 8px;
  background-color: #ecb6c5;
  border-radius: 50%;
  animation: typing 1s infinite ease-in-out;
}

.typing-indicator span:nth-child(2) {
  animation-delay: 0.2s;
}

.typing-indicator span:nth-child(3) {
  animation-delay: 0.4s;
}

@keyframes typing {
  0%, 100% { transform: translateY(0); }
  50% { transform: translateY(-4px); }
}

.error-message {
  color: #ff4d4d;
  padding: 14px;
  background-color: #fff;
  border: 1px solid #ff4d4d;
  border-radius: 6px;
  margin: 12px 0;
}

.message-form {
  padding: 20px;
  border-top: 1px solid rgba(236, 182, 197, 0.3);
  background-color: #fff;
}

.input-area {
  display: flex;
  gap: 12px;
}

.message-input {
  flex: 1;
  padding: 12px 16px;
  border: 1px solid rgba(236, 182, 197, 0.3);
  border-radius: 8px;
  resize: none;
  min-height: 44px;
  max-height: 150px;
  line-height: 1.5;
  font-family: inherit;
  font-size: 14px;
  transition: border-color 0.3s ease, box-shadow 0.3s ease;
}

.message-input:focus {
  outline: none;
  border-color: #ecb6c5;
  box-shadow: 0 0 0 3px rgba(236, 182, 197, 0.2);
}

.send-button {
  padding: 12px 24px;
  border: none;
  border-radius: 8px;
  background-color: #ecb6c5;
  color: #000;
  cursor: pointer;
  font-weight: 500;
  font-size: 14px;
  display: flex;
  align-items: center;
  justify-content: center;
  min-width: 90px;
  transition: all 0.3s ease;
}

.send-button:hover:not(:disabled) {
  background-color: #e5a3b5;
  transform: translateY(-1px);
}

.send-button:active:not(:disabled) {
  transform: translateY(0);
}

.send-button:disabled {
  background-color: rgba(236, 182, 197, 0.5);
  cursor: not-allowed;
}

.close-button-panel {
  background: none;
  border: none;
  padding: 8px;
  cursor: pointer;
  border-radius: 6px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #666;
  transition: all 0.3s ease;
}

.close-button-panel:hover {
  background-color: rgba(236, 182, 197, 0.1);
  color: #333;
}

.header-buttons {
  display: flex;
  gap: 8px;
  align-items: center;
}

.edit-button {
  background: none;
  border: none;
  padding: 8px;
  cursor: pointer;
  border-radius: 6px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #666;
  transition: all 0.3s ease;
}

.edit-button:hover {
  background-color: rgba(236, 182, 197, 0.1);
  color: #333;
}

.edit-button.active {
  background-color: #ecb6c5;
  color: #000;
}

.manual-edit-container {
  flex: 1;
  display: flex;
  flex-direction: column;
  background-color: #fafafa;
  overflow: hidden;
}