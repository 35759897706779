.center-container {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100vw;
    z-index: 2001;
    
}


.remindmap-container {
    display: flex;
    flex-direction: row;
    align-items:center;
    justify-content:space-between;
    background-color: #333; /* Black background */
    padding: 10px 20px;
    border-radius: 30px; /* Rounded corners */
    color: white;
    font-size: 18px;
    font-family: Arial, sans-serif;
    max-width: 500px;
}

.remap-button {
    display: flex;
    align-items: center;
    background-color: #ccc; /* Light gray background */
    color: black;
    padding: 5px 10px;
    border: none;
    border-radius: 20px;
    margin-left: 10px;
    cursor: pointer;
    font-size: 18px;
    font-family: Arial, sans-serif;
}

.remap-button .sparkle-icon {
    margin-left: 5px;
    font-size: 18px; /* Adjust size as needed */
}
