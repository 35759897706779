.support-page-container {
    padding: 20px; /* Add some padding around the container */
    display: flex;
    flex-direction: column;
    align-items: center; /* Center the content */
    justify-content: center;
  }
  
  .airtable-embed {
    max-width: 800px; /* Maximum width of the form for larger screens */
    width: 100%; /* Make the iframe responsive */
    height: 810px;; /* Fixed height as per your requirement */
    border: 1px solid #ccc; /* Border as per your iframe style */
  }
  