.summary-card {
    position: relative;
    color: #2e2e2f;
    cursor: move;
    background-color: #fff;
    padding: 1rem;
    border-radius: 8px;
    box-shadow: rgba(99, 99, 99, 0.1) 0px 2px 8px 0px;
    margin-bottom: 1rem;
    border: 3px dashed transparent;
    min-width: 350px;
    overflow: auto;
  }

  .autosave-status {
    font-weight: 200;
    font-size: 12px;
    text-align: right;
}

  .close-icon-summary{
    width:20px;
  }
  
  .summary-card:hover {
    box-shadow: rgba(99, 99, 99, 0.3) 0px 2px 8px 0px;
    border-color: rgba(162, 179, 207, 0.2) !important;
  }
  
  .summary-card p {
    font-size: 15px;
    margin: 1.2rem 0;
  }
  
  .bottom-information-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  
  .left-group {
    display: flex;
    align-items: center; /* Aligns items in the left group vertically in the center. */
  }
  
  .beta-feature-text p{
    margin-left: 5px;
    margin-right: 10px; /* Adds spacing between the BETA tag and the text, adjust as needed. */
    font-size: 10px;
  }
  
  /* Optional: You might want to add styles for .tag and .beta-feature-text for better appearance */
  .tag.beta {
    background-color: black; /* Example background color for the tag, adjust as needed */
    color:white;
    padding: 5px; /* Adjust padding as needed */
    border-radius: 4px; /* Optional: for rounded corners */
  }

  .tag {
    border-radius: 100px;
    padding: 4px 13px;
    font-size: 12px;
    color: black;
    background-color: pink;
  }
  
  .tags {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  .options{
    background-color: transparent;
    border: none;
  }
  
.editable-summary{
    margin: 1.2rem 0;
    resize: none;
    padding: 1rem;
    width: 100%; /* Ensure it fills the container */
    box-sizing: border-box; /* Include padding and border in the element's total width and height */
  }
  
  @keyframes grow-shrink {
    0%, 100% {
      transform: scale(1); /* Original size */
    }
    50% {
      transform: scale(1.2); /* 50% larger */
    }
  }

  .ai-summary-button{
    background-color: lightgray;
    border: none;
    padding: 14px;
    border-radius: 24px;
    cursor: pointer;
    margin: 5px;
  }

  .ai-icon{
    width: 30px;
    margin-left: 5px;
    animation: grow-shrink 3s infinite;
  }

  .summary-card {
    position: relative;
    transition: filter 0.3s ease;
  }
  
  .summary-card.blur {
    filter: blur(4px);
    pointer-events: none; /* Optional: to disable interactions */
  }
  
  .loader-overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    background: rgba(255, 255, 255, 0.8); /* Semi-transparent background */
    z-index: 1000;
  }
  
  .summary-loader {
    border: 8px solid #f3f3f3;
    border-top: 8px solid #3498db;
    border-radius: 50%;
    width: 50px;
    height: 50px;
    animation: spin 1s linear infinite;
  }
  
  @keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
  }


  .button-container {
    position: relative;
    display: inline-block;
  }
  
  .tooltip {
    visibility: hidden;
    background-color: black;
    color: #fff;
    text-align: center;
    border-radius: 5px;
    padding: 5px;
    position: absolute;
    z-index: 1;
    bottom: 125%; /* Position the tooltip above the button */
    left: 50%;
    margin-left: -60px;
    opacity: 0;
    transition: opacity 0.3s;
    width: 120px;
  }
  
  .button-container:hover .tooltip {
    visibility: visible;
    opacity: 1;
  }

