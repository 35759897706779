.breadcrumb {
    padding: 15px 20px;
    font-size: 18px;
    font-family: Arial, sans-serif;
    color: #333;
  }
  
  .breadcrumb-link {
    text-decoration: none;
    color: #212121;
    font-weight: 500;
  }
  
  .breadcrumb-link:hover {
    text-decoration: underline;
  }
  
  .breadcrumb-separator {
    color: #333;
    margin: 0 5px; /* Adds space around the separator without affecting the underline */
  }

  .use-cases-heading{
    padding-top: 0px;
  }
  