.generation-confirmation {
  width: 450px; /* Adjust as needed */
  background: white;
  border-radius: 5px;
  z-index: 1000;
  display: flex;
  margin: auto;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;
}

.confirmation-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}

.confirmation-header .close-button {
  background: none;
  border: none;
  font-size: 24px;
  cursor: pointer;
}

.confirmation-body {
  text-align: center;
  width: 100%;
  display:flex;
  flex-direction: column;
  align-items: center;
  justify-content: center; 
}

.confirmation-actions {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 20px;
  width: 100%;
  gap: 10px; /* Add space between buttons */
}

.confirmation-error {
  font-size: 15px;
  color: red;
  text-align: center;
}

/* Style for the primary action button */
.upgrade {
  background: #C880FF; /* Purple color for the upgrade button */
  color: white;
  padding: 10px 30px;
  font-size: 18px;
  border: none;
  border-radius: 25px; /* Pill-shaped rounded border */
  font-weight: 500;
  cursor: pointer;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  margin: 20px 0;
  width: auto; /* Let the button size according to its content */
}

/* Assuming these are the cancel and submit buttons in your modal */
.cancelButton {
  background-color: #f0f0f0; /* Neutral grey for cancel button */
  color: #333; /* Darker text for contrast */
  padding: 10px 20px;
  font-size: 1em;
  border: none;
  border-radius: 18px;
  cursor: pointer;
  flex-grow: 1; /* Flex grow to ensure the button takes appropriate space */
  margin-right: 10px; /* Add margin for spacing between buttons */
}

.submitButton {
  background-color: #f570ffff; /* Provided color for submit button */
  color: white; /* White text for contrast */
  padding: 10px 20px;
  font-size: 1em;
  border: none;
  border-radius: 18px;
  cursor: pointer;
  flex-grow: 1; /* Flex grow to ensure the button takes appropriate space */
}

.header-tab {
  background: none;
  border: none;
  padding: 8px 16px;
  cursor: pointer;
  font-size: 16px;
  color: #000;
  outline: none;
}

.header-tab.active {
  color: #555;
  border-bottom: 3px solid #555;
}

.tab-icon {
  height: 18px;
  width: 18px;
  margin-right: 8px;
}

.button-icon {
  height: 18px;
  width: 18px;
  margin-right: 8px;
}

/* You can add media queries and other necessary styling as needed. */
