.react-flow__node-mindmap {
    border-radius: 2px;
    border: 1px solid transparent;
    padding: 2px 5px;
    font-weight: 700;
  }
  .inputWrapper{
    display: flex;
    flex-direction: row;
    align-items: center;
  }
  

  .inputNode {
    background-color: inherit;
    border-radius: 0px;
    border: none;
    padding: 2px 5px;
    font-size: 12px;
    font-weight: 700;
    color: black;
    width: auto; /* Allow the width to adjust dynamically */
    min-width: 50px; /* Optional: sets a base minimum width */
    max-width: 200px; /* Optional: limits the width */
    box-sizing: border-box;
    outline: none;
    white-space: normal; /* Allow text to wrap on focus */
    word-wrap: break-word; /* Break text at words */
    overflow: auto; /* Show scroll bars if the text overflows */
    text-overflow: auto; /* Add ellipsis for overflowed text */
    resize: none; /* Disable manual resizing */
}

.inputNode:focus {
    background: #fff;
    border: 1px solid #ddd;
    white-space: normal; /* Allow text to wrap on focus */
    word-wrap: break-word; /* Break text at words */
    overflow: auto; /* Show scroll bars if the text overflows */
}
  
  
  .dragHandle {
    background: transparent;
    width: 14px;
    height: 100%;
    margin-right: 4px;
    display: flex;
    align-items: center;
    pointer-events: all;
  }
  

  /* Set the default white background for the node */
.inputWrapper {
  background-color: white;
  border: 1px solid #ddd;
  border-radius: 8px;
  padding: 8px;
  transition: all 0.3s ease;
}

/* Add pink border on hover */
.inputWrapper:hover {
  border-color: pink;
  box-shadow: 0 0 5px pink;
}

/* Add pink border when the node is selected (using a `selected` class) */
.inputWrapper.selected {
  border-color: pink;
  box-shadow: 0 0 8px pink;
}

/* Style for the textarea inside the node */
.inputNode {
  width: 100%;
  border: none;
  outline: none;
  background-color: transparent;
  font-size: 14px;
  color: #333;
}

/* Focused state for textarea */
.inputNode:focus {
  outline: none;
}
